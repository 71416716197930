export const appRoutes = {
    home: '/',
    auth: {
        login: '/login',
        register: '/register',
        forgotPassword: '/forgot-password',
        resetPassword: '/reset-password/:token',
        accountCreated: '/account-confirmation',
        accountActivation: '/activate/:token',
    },
    analyze: {
        index: '/analyze/:sessionId',
    },
    explore: {
        index: '/explore',
        tracks: {
            index: '/explore/tracks',
            addTrack: '/explore/tracks/add',
            details: '/explore/tracks/:country/:trackSlug',
            addLayout: '/explore/tracks/:country/:trackSlug/add-layout',
            layoutDetailsTable: '/explore/tracks/:country/:trackSlug/layout/:layoutSlug/results',
        },
        events: {
            index: '/explore/events',
            eventDetails: '/explore/events/:eventSlug',
            eventBestLapResults: '/explore/events/:eventSlug/best-lap-results',
        },
        drivers: {
            index: '/explore/drivers',
            details: '/explore/drivers/:driverSlug',
            vehicles: {
                index: '/explore/drivers/:driverSlug/vehicles',
                details: '/explore/drivers/:driverSlug/vehicles/:vehicleId',
            },
            events: '/explore/drivers/:driverSlug/events',
            sessions: '/explore/drivers/:driverSlug/sessions',
            tracks: '/explore/drivers/:driverSlug/tracks',
        },

        vehicles: {
            index: '/explore/vehicles',
        },
        sessions: '/explore/sessions/:driver/:sessionId',
    },
    activity: {
        index: '/activity',
        sessions: {
            index: '/activity/sessions',
            details: '/activity/sessions/details/:sessionId',
            upload: '/activity/sessions/upload',
        },
        vehicles: {
            index: '/activity/vehicles',
            details: '/activity/vehicles/:vehicleId/details',
            add: '/activity/vehicles/add',
        },
        events: {
            index: '/activity/events',
            add: '/activity/events/add',
        },
        tracks: {
            index: '/activity/tracks',
        },
    },
    account: {
        index: '/account',
    },
    about: {
        index: '/about',
    },
    recommend: {
        index: '/recommend',
    },
    general: {
        notifications: { index: '/notifications' },
    },
    errors: {
        index: '/error',
    },
};
