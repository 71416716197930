import { Box } from '../Box';
import { styled } from 'Theme/stitches.config';

export const AvatarGroupRoot = styled(Box, {
    position: 'relative',
    minWidth: '110px',
    minHeight: '38px',
});

export const AvatarGroupElement = styled(Box, {
    position: 'absolute',
    top: 0,
    border: '3px solid $componentBackground',
    borderRadius: '100%',
});
