import { FC } from 'react';

import { DimmerOverlay } from './styles';
import Loader from 'Atoms/Loader';

interface DimmerProps {
    active: boolean;
}

const Dimmer: FC<DimmerProps> = ({ active }) => {
    return active ? (
        <DimmerOverlay>
            <Loader height={60} textSize='h2' />
        </DimmerOverlay>
    ) : null;
};

export default Dimmer;
