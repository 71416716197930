import { keyframes } from '@stitches/react';

import { styled } from 'Theme/stitches.config';

const dash = keyframes({
    '0%': { strokeDashoffset: 90, transform: 'rotate(0deg)' },
    '50%': { strokeDashoffset: 20, transform: 'rotate(45deg)' },
    '100%': { strokeDashoffset: 90, transform: 'rotate(360deg)' },
});

const rotate = keyframes({
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
});

export const SpinnerCircle = styled('svg', {
    animation: `${rotate} 2s infinite linear`,

    circle: {
        strokeDasharray: '100',
        animation: `${dash} 1.3s infinite`,
        strokeLinecap: 'round',
        transformOrigin: 'center',
        strokeDashoffset: '20',
    },
});
