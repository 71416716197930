import { FC } from 'react';

import { Flex } from 'Atoms/Flex';
import Spinner from 'Atoms/Spinner';
import { Text } from 'Atoms/Text';
import { styled } from 'Theme/stitches.config';

interface LoaderProps {
    height?: number;
    textSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'md' | 'sm';
}

const Loader: FC<LoaderProps> = ({ height = 30, textSize = 'h3' }) => (
    <LoaderWrapper align='center' gap='lg' justify='center'>
        <Spinner height={height} /> <Text size={textSize}>Loading...</Text>
    </LoaderWrapper>
);

export default Loader;

const LoaderWrapper = styled(Flex, {
    padding: '25px 0',
});
