import { CSS } from '@stitches/react';
import { FC } from 'react';

import { MessageWrapper } from './styles';
import Icon from 'Atoms/Icon';
import { Text } from 'Atoms/Text';

interface MessageBarProps {
    variant: 'error' | 'info';
    message: string;
    centerText?: boolean;
    fullWidth?: boolean;
    css?: CSS;
}

const MessageBar: FC<MessageBarProps> = ({ message, variant, centerText, fullWidth, css }) => {
    return (
        <MessageWrapper
            css={{ justifyContent: centerText ? 'center' : 'start', ...css }}
            fullWidth={fullWidth}
            variant={variant}
        >
            {variant === 'error' ? (
                <Icon color='#C70712' name='info' size={18} />
            ) : (
                <Icon color='#2356cc' name='info' size={18} />
            )}
            <Text
                color={variant === 'error' ? 'error' : 'info'}
                css={{ marginTop: '$2' }}
                size='sm'
                weight='400'
            >
                {message}
            </Text>
        </MessageWrapper>
    );
};

export default MessageBar;
