import React from 'react';
import { Link } from 'react-router-dom';

import { ActiveGreenDot, AvatarFallback, AvatarImage, AvatarRoot } from './styles';

export interface AvatarProps {
    /** best way to use fallback is add first name and last name even if last name does not exist
     * e.g. `${userData.first_name} ${userData.last_name}` or
     * first_name + (last_name && ` ${last_name}`
     *  . */
    fallback: string;
    fallBackFullText?: string; //override fallback
    userName?: string;
    imgSrc?: string;
    round?: boolean;
    size?: 'small' | 'normal' | 'large' | 'extraLarge';
    linkTo?: string;
    active?: boolean;
}

const Avatar: React.FC<AvatarProps> = ({
    fallback,
    fallBackFullText,
    userName,
    imgSrc,
    linkTo,
    round = false,
    size = 'normal',
    active,
}) => {
    let fallbackText = fallBackFullText;
    if (!fallBackFullText) {
        const fallbackArr = fallback.split(' ');

        fallbackText =
            fallbackArr.length > 1
                ? `${fallbackArr[0].charAt(0)}${fallbackArr[fallbackArr.length - 1].charAt(0)}`
                : fallback.substring(0, 2);
    }

    return (
        <>
            {linkTo ? (
                <Link to={linkTo}>
                    <AvatarRoot round={round} size={size}>
                        <AvatarImage alt={userName} src={imgSrc} />
                        <AvatarFallback delayMs={50}>{fallbackText}</AvatarFallback>
                    </AvatarRoot>
                </Link>
            ) : (
                <div style={{ position: 'relative' }}>
                    {active && <ActiveGreenDot />}
                    <AvatarRoot round={round} size={size}>
                        <AvatarImage alt={userName} src={imgSrc} />
                        <AvatarFallback delayMs={50}>{fallbackText}</AvatarFallback>
                    </AvatarRoot>
                </div>
            )}
        </>
    );
};

export default Avatar;
