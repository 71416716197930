import { Map as VisMap, MapProps } from '@vis.gl/react-google-maps';
import { PropsWithChildren } from 'react';

import { MapWrapperBox } from 'Molecules/Map/styles';

const Map = (props: PropsWithChildren<MapProps>) => {
    const { children } = props;
    return (
        <MapWrapperBox>
            <VisMap {...props}>{children}</VisMap>
        </MapWrapperBox>
    );
};

export default Map;
