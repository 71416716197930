import * as Sentry from '@sentry/react';
import axios from 'axios';

import { appRoutes } from 'Routes/routesMap';

import { regenerateToken } from 'Services/auth';

import useStore from 'Store/store';

let isRefreshing: boolean;

export const httpClient = axios.create({
    baseURL: import.meta.env.VITE_APP_API_URL,
});

httpClient.interceptors.request.use(
    async (config) => {
        const token = useStore.getState().auth.token;

        if (token?.jwt) {
            config.headers.Authorization = `Bearer ${token.jwt}`;
        }

        return config;
    },
    (error) => Promise.reject(error)
);

httpClient.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error) {
            Sentry.captureException(error);
        }

        const config = error?.config;

        if (error?.response?.status === 401 && !config?.sent) {
            config.sent = true;
            const token = useStore.getState().auth.token;
            const isLoginError = error.request.responseURL === `${config.baseURL}auth/login`;

            if (!token) {
                return isLoginError
                    ? Promise.reject(error)
                    : window.location.replace(appRoutes.auth.login);
            }

            if (!isRefreshing) {
                isRefreshing = true;
                const result = await regenerateToken({
                    token: token.jwt,
                    refresh_token: token.refresh,
                });

                if (result?.token) {
                    config.headers = {
                        ...config.headers,
                        authorization: `Bearer ${result?.token}`,
                    };
                }

                isRefreshing = false;
            }

            return httpClient(config);
        }

        if (error?.response?.status) {
            // const status = error?.response?.status;
            // const is500 = (error?.response?.status + '')[0] === '5';
            const isTokenRefreshError =
                error.request.responseURL === `${config.baseURL}auth/token/refresh`;

            if (isTokenRefreshError) return;

            // if (is500) {
            //     return window.location.replace(appRoutes.errors.index + '?code=500');
            // }
            // if (status === 403) {
            //     return window.location.replace(appRoutes.errors.index + '?code=403');
            // }
            // if (status === 404) {
            //     return window.location.replace(appRoutes.errors.index + '?code=404');
            // }
        }

        return Promise.reject(error);
    }
);
