import { StateCreator } from 'zustand';

import { StoreType } from './store';

export interface Token {
    jwt: string;
    refresh: string;
    expire: Date;
}

export type AuthSlice = {
    token?: Token | undefined;
    setToken: (value: Token | undefined) => void;
};

export const authSlice: StateCreator<
    StoreType,
    [['zustand/immer', never], ['zustand/devtools', never]],
    [],
    AuthSlice
> = (set) => ({
    token: undefined,

    setToken: (value) =>
        set(
            (state) => {
                state.auth.token = value;
            },
            false,
            'setToken'
        ),
});
