import React from 'react';

import { SpinnerCircle } from './styles';
import { config as themeConfig } from 'Theme/stitches.config';

interface SpinnerProps {
    color?: string;
    thickness?: 1 | 2 | 3 | 4;
    height?: number;
}

const Spinner: React.FC<SpinnerProps> = ({
    color = themeConfig.theme.colors.activeBlue,
    thickness = 2,
    height = 200,
}) => {
    return (
        <SpinnerCircle height={height} viewBox='0 0 24 24'>
            <circle
                cx='12'
                cy='12'
                fill='none'
                pathLength='100'
                r='10'
                stroke={color}
                strokeWidth={thickness}
            />
        </SpinnerCircle>
    );
};

export default Spinner;
