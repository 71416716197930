import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { appRoutes } from 'Routes/routesMap';

import {
    EventDetailsData,
    deleteEventMutation,
    getEventDetailsQuery,
} from 'Services/exploreQueries';
import {
    USER_ACTIVITY_KEY,
    USER_EVENTS_CREATED_KEY,
    USER_EVENTS_JOINED_KEY,
} from 'Services/queryKeys';
import { ApiResponse } from 'Services/response';

export const useEventsDetails = () => {
    const { eventSlug } = useParams();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const location = useLocation();

    const [isErrorEventDetails, setIsErrorEventDetails] = useState(false);

    const { data: eventDetail } = useQuery<ApiResponse<EventDetailsData>>({
        ...getEventDetailsQuery(eventSlug || ''),
    });

    const { mutate: deleteEvent } = useMutation({
        ...deleteEventMutation(),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [USER_EVENTS_CREATED_KEY] });
            queryClient.invalidateQueries({ queryKey: [USER_EVENTS_JOINED_KEY] });
            queryClient.invalidateQueries({ queryKey: [USER_ACTIVITY_KEY] });
            navigate(location.state ? location.state.prevUrl : appRoutes.activity.events.index, {
                replace: true,
            });
        },
        onError: () => {
            setIsErrorEventDetails(true);
        },
    });

    const convertedUsersList = useMemo(
        () =>
            eventDetail?.participants?.map(({ username, avatar }) => ({
                fallback: `${username[0].toUpperCase()}${username[1].toUpperCase()}`,
                imgSrc: avatar,
                userName: username,
            })),
        [eventDetail?.participants]
    );

    return {
        convertedUsersList,
        eventDetail,
        eventTrack: eventDetail?.track,
        isErrorEventDetails,
        deleteEvent,
    };
};
