// --------------------------------------------------------------------
// Login user
import { redirect } from 'react-router-dom';

import { ACTIVATE_ACCOUNT_KEY } from './queryKeys';
import { ApiResponse } from './response';
import { httpClient } from 'Infrastructure/http/client';
import { appRoutes } from 'Routes/routesMap';

import useStore from 'Store/store';

export interface LoginActionRequest {
    email: string;
    password: string;
}

export type LoginActionResponse = {
    token: string;
    token_expires_in: string;
    refresh_token: string;
    first_name: string;
    last_name: string;
    fullName: string;
    id: string;
    nationality: string;
    avatar?: string;
    username: string;
};

const setUserInState = (user: ApiResponse<LoginActionResponse>) => {
    useStore.getState().auth.setToken({
        expire: new Date(user.token_expires_in),
        jwt: user.token,
        refresh: user.refresh_token,
    });
};

export interface LoginSocialRequest {
    access_token: string;
    provider: string;
}

export const authenticateSocialUser = async (authData: LoginSocialRequest) => {
    const user = (await httpClient
        .post(`/auth/social/login`, authData)
        .then((resp) => resp.data)
        .catch((err: Error) => ({
            error: err.message,
        }))) as ApiResponse<LoginActionResponse>;

    if (user) {
        setUserInState(user);
    }
    return user;
};

// --------------------------------------------------------------------
// Refresh token
export interface RefreshTokenActionRequest {
    token: string;
    refresh_token: string;
}

export type RefreshTokenActionResponse = {
    token: string;
    token_expires_in: string;
    refresh_token: string;
};

export const regenerateToken = async (
    tokenData: RefreshTokenActionRequest
): Promise<ApiResponse<RefreshTokenActionResponse>> => {
    try {
        const user = (await httpClient
            .post(`/auth/token/refresh`, tokenData)
            .then((resp) => resp.data)) as ApiResponse<RefreshTokenActionResponse>;

        if (user) {
            useStore.getState().auth.setToken({
                expire: new Date(user.token_expires_in),
                jwt: user.token,
                refresh: user.refresh_token,
            });
        }

        return user;
    } catch (e) {
        useStore.getState().auth.setToken(undefined);

        return Promise.reject<ApiResponse<RefreshTokenActionResponse>>(e);
    }
};

export const refreshToken = async () => {
    const token = useStore.getState().auth.token;

    try {
        if (token) {
            const response = await regenerateToken({
                token: token.jwt,
                refresh_token: token.refresh,
            });

            if (!response.token) {
                useStore.getState().auth.setToken(undefined);
                return redirect(appRoutes.auth.login);
            }

            httpClient.interceptors.request.use(
                async (config) => {
                    const token = useStore.getState().auth.token;

                    if (token?.jwt) {
                        config.headers.Authorization = `Bearer ${token.jwt}`;
                    }

                    return config;
                },
                (error) => Promise.reject(error)
            );
        }
    } catch (err) {
        console.error(err);
        useStore.getState().auth.setToken(undefined);
        return redirect(appRoutes.auth.login);
    }
};

// --------------------------------------------------------------------
// Forgot password

export interface ForgotEmailArgs {
    email: string;
}

export const forgotEmail = async (args: ForgotEmailArgs) => {
    const response = await httpClient.post('/auth/forgot-password', args);
    return response.data;
};

export const forgotEmailMutation = () => ({
    mutationFn: async (args: ForgotEmailArgs) => forgotEmail(args),
});

// --------------------------------------------------------------------
// Reset password
interface ResetPasswordArgs {
    token?: string;
    new_password: string;
    new_password_confirm: string;
}

export const resetPassword = async (args: ResetPasswordArgs) => {
    const response = await httpClient.post('/auth/update-password', { ...args });
    return response.data;
};

export const resetPasswordMutation = () => ({
    mutationFn: async (args: ResetPasswordArgs) => resetPassword(args),
});

// Login
interface LoginArgs {
    email: string;
    password: string;
}

export const login = async (args: LoginArgs) => {
    const response = await httpClient.post('/auth/login', { ...args });
    return response.data;
};

export const loginMutation = () => ({
    mutationFn: async (args: LoginArgs) => login(args),
});
// Register new user
interface RegisterActionRequest {
    first_name: string;
    email: string;
    password: string;
}
export interface RegistrationResponse {
    email_service: string;
    id: string;
    refresh_token: string;
    token: string;
    token_expires_in: string;
}

export const registerNewUser = async (args: RegisterActionRequest) => {
    const response = await httpClient.post('/drivers', { ...args });
    return response.data;
};

export const registerNewUserMutation = () => ({
    mutationFn: async (args: RegisterActionRequest) => registerNewUser(args),
});

// Activate account

export interface ActivateAccountResponse {
    refresh_token: string;
    token: string;
    token_expires_in: Date;
}
export const activateAccountQuery = (key: string) => ({
    queryKey: [ACTIVATE_ACCOUNT_KEY],
    queryFn: async (): Promise<ActivateAccountResponse> => {
        const response = await httpClient.post(`/auth/activate`, {
            token: key
        });
        return response.data;
    },
});
