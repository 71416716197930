import { AnalyzeDataTick } from 'Services/analyze/types';

export interface ChannelProps {
    device_id: string;
    id: string;
    name: string;
    type: string;
    unit: string;
}

export interface SessionTick extends AnalyzeDataTick {
    analyzeTimer: number;
}

export interface TimelineProps {
    initialStart?: number; //initial start of session in seconds
    initialFinish?: number; //initial finish of session in seconds

    selectedStart?: number; //selected start of session in seconds
    selectedFinish?: number; //selected finish of session in seconds
}

export enum TimerStatusModel {
    Idle = 0,
    Running = 1,
    Pause = 2,
    Continue = 3,
}

export interface AnalyzeSession {
    selectedLap: number | undefined;
    start: number;
    finish: number;
    isPrimary: boolean;
}
export interface LapsToCompareModel {
    id: string;
    sessionId: string;
    lapNumber: number;
    driverFirstName: string;
    driverLastName: string;
    bestTime?: boolean;
}

export interface AnalyzeSessionData {
    sessionId: string;
    data: SessionTick[];
    start: number;
    finish: number;
}
