import { CSS, VariantProps } from '@stitches/react';
import { FC, MouseEvent } from 'react';
import { Link } from 'react-router-dom';

import { StyledButton } from './styles';
import Icon, { IconName } from '../Icon';
import { Text } from '../Text';

type TextProps = VariantProps<typeof Text>;

interface ButtonProps {
    isDisabled?: boolean;
    children?: React.ReactNode;
    type?: 'button' | 'reset' | 'submit';
    width?: string;
    minWidth?: string;
    maxWidth?: string;
    variant?:
        | 'primaryBlue'
        | 'secondaryBlue'
        | 'primaryRed'
        | 'primaryBlack'
        | 'secondaryGray'
        | 'transparent'
        | 'transparentBordered';
    onClick?: (event: MouseEvent) => void;
    hasIcon?: boolean;
    isLink?: boolean;
    to?: string;
    centerText?: boolean;
    name?: string;
    size?: 'xs' | 'sm' | 'md' | 'lg';
    prevUrl?: string;
    icon?: IconName | false;
    css?: CSS;
}

const Button: FC<ButtonProps> = ({
    isDisabled,
    children,
    type,
    width,
    minWidth,
    maxWidth,
    variant,
    onClick,
    hasIcon = true,
    isLink,
    to,
    prevUrl,
    centerText,
    name,
    size,
    icon,
    css,
}) => {
    const attr = isLink ? { to, as: Link, state: { prevUrl } } : { disabled: isDisabled };
    icon = icon ?? (hasIcon ? 'arrow-button' : undefined);

    let textSize: TextProps['size'] = 'default';
    let iconsSize: number = 18;
    switch (size) {
        case 'xs':
            textSize = 'sm';
            iconsSize = 16;
            break;
        case 'sm':
            textSize = 'sm';
            iconsSize = 18;
            break;
        case 'md':
            textSize = 'md';
            break;
        case 'lg':
            textSize = 'h3';
            break;
    }

    return (
        <StyledButton
            centerText={centerText}
            css={{ width: width, minWidth: minWidth, maxWidth: maxWidth, ...css }}
            name={name}
            onClick={onClick}
            type={type || 'button'}
            variant={variant}
            {...attr}
            size={size}
        >
            {children && (
                <Text as={'span'} css={{ width: '100%' }} size={textSize}>
                    {children}
                </Text>
            )}

            {icon && <Icon name={icon} size={iconsSize} />}
        </StyledButton>
    );
};

export default Button;
