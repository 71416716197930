import { useQuery } from '@tanstack/react-query';

import { UserProfileDTO, getUserProfileQuery } from 'Services/activityQueries';
import { ApiResponse } from 'Services/response';

export const useLoggedUserData = () => {
    const { data: userData } = useQuery<ApiResponse<UserProfileDTO>>({
        ...getUserProfileQuery(),
    });

    return { userData };
};
