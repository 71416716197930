import { StateCreator } from 'zustand';

import { StoreType } from './store';

export type GeneralSlice = {
    isMobileNavOpen: boolean;
    setIsMobileNavOpen: (value: boolean) => void;
};

export const generalSlice: StateCreator<
    StoreType,
    [['zustand/immer', never], ['zustand/devtools', never]],
    [],
    GeneralSlice
> = (set) => ({
    isMobileNavOpen: false,
    setIsMobileNavOpen: (value) =>
        set(
            (state) => {
                state.general.isMobileNavOpen = value;
            },
            false,
            'setIsMobileNavOpen'
        ),
});
