import { styled } from 'Theme/stitches.config';

export const Flex = styled('div', {
    display: 'flex',
    variants: {
        wrap: {
            wrap: {
                flexWrap: 'wrap',
            },
            'no-wrap': {
                flexWrap: 'nowrap',
            },
            'wrap-reverse': {
                flexWrap: 'wrap-reverse',
            },
        },
        direction: {
            row: {
                flexDirection: 'row',
            },
            column: {
                flexDirection: 'column',
            },
            'row-reverse': {
                flexDirection: 'row-reverse',
            },
            'column-reverse': {
                flexDirection: 'column-reverse',
            },
        },
        justify: {
            start: {
                justifyContent: 'flex-start',
            },
            center: {
                justifyContent: 'center',
            },
            end: {
                justifyContent: 'flex-end',
            },
            stretch: {
                justifyContent: 'stretch',
            },
            'space-between': {
                justifyContent: 'space-between',
            },
            'space-around': {
                justifyContent: 'space-around',
            },
        },
        align: {
            start: {
                alignItems: 'flex-start',
            },
            center: {
                alignItems: 'center',
            },
            end: {
                alignItems: 'flex-end',
            },
            stretch: {
                alignItems: 'stretch',
            },
        },
        gap: {
            none: {
                gap: 0,
            },
            sm: {
                gap: '4px',
            },
            md: {
                gap: '8px',
            },
            lg: {
                gap: '16px',
            },
            xl: {
                gap: '24px',
            },
            xxl: {
                gap: '32px',
            },
            xxxl: {
                gap: '48px',
            },
        },
        display: {
            flex: {
                display: 'flex',
            },
            inline: {
                display: 'inline-flex',
            },
        },
        isFullWidth: {
            true: {
                width: '100%',
            },
        },
    },
});

export const Row = styled(Flex, {
    flexDirection: 'row',
    variants: {
        reverse: {
            true: {
                flexDirection: 'row-reverse',
            },
        },
    },
});

export const Column = styled(Flex, {
    flexDirection: 'column',
    variants: {
        reverse: {
            true: {
                // neat way to create boolean variants in stitches
                flexDirection: 'column-reverse',
            },
        },
    },
});
