import { Box } from 'Atoms/Box';
import { styled } from 'Theme/stitches.config';

export const Layout = styled(Box, {
    mx: 'auto',
    maxWidth: '1880px',
    display: 'flex',

    variants: {
        fullWidth: {
            true: {
                maxWidth: '100%',
            },
        },
    },
});

export const SidebarColumn = styled(Box, {
    display: 'none',
    flex: '0 0 230px',
    background: '$lightGray',
    pad: '$24 $16 $16',
    position: 'sticky',
    top: 0,
    height: '100vh',

    '@smallDesktop': {
        display: 'block',
    },
    '@desktop': {
        flex: '0 0 280px',
    },
});

export const MainColumn = styled(Box, {
    width: '100%',
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'space-between',
});

export const MainColumnContent = styled(Box, {
    flex: '1 1 auto',
    padding: '$18',

    '@smallDesktop': {
        padding: '$24',
    },
});

export const MainColumnContentAnalyzer = styled(Box, {
    width: '100%',
    height: 'calc(100vh - 24px)',
    padding: '$24',
    position: 'relative',

    '::-webkit-scrollbar': {
        width: '0.25rem',
        height: '0.25rem',
        direction: 'rtl',
    },

    '::-webkit-scrollbar-thumb': {
        background: '$mainBlack',
        borderRadius: '1rem',
        direction: 'rtl',
    },
});

export const MainColumnAside = styled(Box, {
    mr: '$32',
    display: 'none',
    position: 'sticky',
    top: 0,
    paddingBlock: '$24',
    height: '100vh',

    '@desktop': {
        display: 'block',
        flex: '0 0 316px',
    },
    '@full_hd': {
        flex: '0 0 376px',
    },
});
