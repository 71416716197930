import { FC } from 'react';

import Avatar from 'Atoms/Avatar';
import { Flex } from 'Atoms/Flex';
import {
    Position,
    Driver,
    Result,
    DriverName,
    VehicleName,
    ResultTime,
    EventResultRowWrapper,
    PositionText,
} from 'Molecules/EventBestLapResultRow/styles';

import { EventLeaderboardResult } from 'Services/leaderboard';

interface EventBestLapsResultRowProps {
    visible: boolean;
    row: EventLeaderboardResult;
    onClick?: () => void;
    highlighted?: boolean;
    selected?: boolean;
    isClickable?: boolean;
}
const EventBestLapResultRow: FC<EventBestLapsResultRowProps> = ({
    visible,
    row,
    onClick,
    highlighted = false,
    selected = false,
    isClickable = false,
}) => {
    const renderPosition = (position: number) => {
        switch (position) {
            case 1:
                return '🥇';
            case 2:
                return '🥈';
            case 3:
                return '🥉';
            default:
                return position + '.';
        }
    };

    return (
        <EventResultRowWrapper
            highlighted={highlighted}
            isClickable={isClickable}
            onClick={onClick}
            selected={selected}
            visible={visible}
        >
            <Position>
                <PositionText>{renderPosition(row.position)}</PositionText>
            </Position>

            <Flex align='center' gap='md'>
                <Avatar fallback={row.driver.name} imgSrc={row.driver.avatar} size='small' />
                <Driver>
                    <DriverName>{row.driver.name}</DriverName>
                    <VehicleName>{row.vehicle_name}</VehicleName>
                </Driver>
            </Flex>

            <Result>
                <ResultTime>{row.best_lap_time}</ResultTime>
            </Result>
        </EventResultRowWrapper>
    );
};

export default EventBestLapResultRow;
