import moment from 'moment';

// example return: 12 May, 2021
export const displayFullDate = (date: string) => {
    return moment(date).format('D MMMM, YYYY');
};

// example return: 10:28 pm
export const displayTime = (date: string) => {
    return moment(date).format('h:mm a');
};

export const getFormDateFormat = (date: string) => {
    return moment(date).format('YYYY-MM-DD');
};

// example return: 01:40.096
export const convertSecondsToTimeFormat = (seconds: number): string => {
    // Calculate minutes
    const minutes: number = Math.floor(seconds / 60);
    // Calculate remaining seconds
    const remainingSeconds: number = seconds % 60;
    // Calculate seconds and milliseconds
    const sec: number = Math.floor(remainingSeconds);
    const ms: number = Math.floor((remainingSeconds - sec) * 1000);
    // Format the output
    const minutesStr: string = minutes.toString().padStart(2, '0');
    const secondsStr: string = sec.toString().padStart(2, '0');
    const msStr: string = ms.toString().padStart(3, '0');
    return `${minutesStr}:${secondsStr}.${msStr}`;
};

export const formatSecondsToMMSS = (s: number): string => {
    const date = new Date(s); // Convert seconds to milliseconds
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();
    const milliseconds = date.getUTCMilliseconds();

    return `${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}.${milliseconds}`;
};
