import { styled } from 'Theme/stitches.config';

export const MapOverlayWrapper = styled('div', {
    position: 'relative',
    width: '100%',
    height: '100%',
});

export const Overlay = styled('div', {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    variants: {
        show: {
            true: {
                background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
            },
            false: {
                background: 'rgba(0, 0, 0, 0.0)', // Full-transparent black background
            },
        },
    },
});

export const OverlayButton = styled('div', {
    position: 'absolute',
    bottom: '10%',
    left: '10%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pad: '$16',

    border: 0,
    borderRadius: '$4',
    cursor: 'pointer',
    fontWeight: 500,
    fontSize: '$16',
    textAlign: 'left',
    //transform: 'translate(-50%, -50%)', // Center the button

    backgroundColor: '$activeBlue',
    color: '$mainWhite',
    '&:hover': {
        backgroundColor: '$mainGreen',
        color: '$mainWhite',
    },

    variants: {
        firstPlayer: {
            true: {
                backgroundColor: '$mainRed',
                '&:hover': {
                    backgroundColor: '$secondaryRed',
                    opacity: 0.8,
                },
            },
            false: {
                backgroundColor: '$activeBlue',
                '&:hover': {
                    backgroundColor: '$mainGreen',
                    opacity: 0.8,
                },
            },
        },
    },
});
