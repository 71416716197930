import { StateCreator } from 'zustand';

import { StoreType } from './store';

export type EventSlice = {
    isEventDetailsEditorOpen: boolean;
    setIsEventDetailsEditorOpen: (value: boolean) => void;
};

export const eventSlice: StateCreator<
    StoreType,
    [['zustand/immer', never], ['zustand/devtools', never]],
    [],
    EventSlice
> = (set) => ({
    isEventDetailsEditorOpen: false,
    setIsEventDetailsEditorOpen: (value) =>
        set(
            (state) => {
                state.event.isEventDetailsEditorOpen = value;
            },
            false,
            'setIsEventDetailsEditorOpen'
        ),
});
