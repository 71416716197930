import { StateCreator } from 'zustand';

import { StoreType } from './store';

export type TrackSlice = {
    isTrackDetailsEditorOpen: boolean;
    setIsTrackDetailsEditorOpen: (value: boolean) => void;
};

export const trackSlice: StateCreator<
    StoreType,
    [['zustand/immer', never], ['zustand/devtools', never]],
    [],
    TrackSlice
> = (set) => ({
    isTrackDetailsEditorOpen: false,
    setIsTrackDetailsEditorOpen: (value) =>
        set(
            (state) => {
                state.track.isTrackDetailsEditorOpen = value;
            },
            false,
            'setIsTrackDetailsEditorOpen'
        ),
});
