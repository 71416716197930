import { FC } from 'react';
import { Link, generatePath, useMatch } from 'react-router-dom';

import Notifications from './Notifications';
import { Box } from 'Atoms/Box';
import Button from 'Atoms/Button';
import { Flex } from 'Atoms/Flex';
import Icon from 'Atoms/Icon';
import { Text } from 'Atoms/Text';
import { TopBarWrapper } from 'Components/global/styles';
import { useLoggedUserData } from 'Helpers/useLoggedUserData';
import { useResolution } from 'Hooks/useResolution';
import MobileButtonsNav from 'Molecules/MobileButtonsNav';
import LoggedUserAvatar from 'Organisms/LoggedUserAvatar';
import { appRoutes } from 'Routes/routesMap';

import useStore from 'Store/store';

interface UserPanelProps {
    isMobile?: boolean;
}

const UserPanel: FC<UserPanelProps> = ({ isMobile }) => {
    const { userData } = useLoggedUserData();
    const { isSmallDesktopWidth, isDesktopWidth } = useResolution();
    const { setIsLogOutModalOpen } = useStore((state) => state.modals);

    const isMyActivity = !!useMatch(appRoutes.activity.index);
    const isTwoColumn = isSmallDesktopWidth ? isDesktopWidth : true;

    if (!userData || !isTwoColumn) {
        return <></>;
    }
    const toUserLink = generatePath(appRoutes.activity.index);
    return (
        <TopBarWrapper css={{ justifyContent: 'end' }}>
            {!isMyActivity && (
                <Box css={{ flex: '1 0 auto' }}>
                    <Flex align='center'>
                        <LoggedUserAvatar />
                        <Text css={{ ml: '$12' }}>
                            Hi,{' '}
                            <Text as={Link} color='gray' to={toUserLink}>
                                {userData.first_name}{' '}
                            </Text>
                            👋
                        </Text>
                    </Flex>
                </Box>
            )}

            <Flex gap='md'>
                {!isMobile && (
                    <>
                        <Notifications />
                        <Button
                            css={{ padding: '0 4px', height: '38px' }}
                            hasIcon={false}
                            onClick={() => {
                                setIsLogOutModalOpen(true);
                            }}
                            variant='transparent'
                        >
                            <Icon color='black' name='log-out' />
                        </Button>
                    </>
                )}
                {isMobile && <MobileButtonsNav />}
            </Flex>
        </TopBarWrapper>
    );
};

export default UserPanel;
