import {
    createContext,
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useContext,
    useState,
} from 'react';
interface InitialTypes {
    isPlaying: boolean;
    setIsPlaying: Dispatch<SetStateAction<boolean>>;

    followCar: number | undefined;
    setFollowCar: Dispatch<SetStateAction<number | undefined>>;
}

export const MapLapOverlayContext = createContext<InitialTypes | undefined>(undefined);

export const useMapLapOverlayContext = () => {
    const mapLapOverlayState = useContext(MapLapOverlayContext);

    if (!mapLapOverlayState) {
        throw new Error('You have to add MapLapOverlayContextWrapper');
    }

    const { isPlaying, setIsPlaying, setFollowCar, followCar } = mapLapOverlayState;

    return {
        isPlaying,
        setIsPlaying,
        followCar,
        setFollowCar,
    };
};

export const MapLapOverlayContextWrapper = ({ children }: PropsWithChildren) => {
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [followCar, setFollowCar] = useState<number | undefined>();
    return (
        <MapLapOverlayContext.Provider
            value={{
                isPlaying,
                setIsPlaying,
                followCar,
                setFollowCar,
            }}
        >
            {children}
        </MapLapOverlayContext.Provider>
    );
};
