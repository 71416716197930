import { NOTIFICATIONS_KEY } from './queryKeys';
import { httpClient } from 'Infrastructure/http/client';

export interface NotificationData {
    id: number;
    recipient_id: string;
    title: string;
    message: string;
    redirect_uri: string;
    read: boolean;
    image_url: string;
    created_at: string;
    updated_at: string | null;
}

export const getNotifications = async () => {
    const response = await httpClient.get(`/notifications`);
    return response.data;
};

export const getNotificationsQuery = () => ({
    queryKey: [NOTIFICATIONS_KEY],
    queryFn: async () => getNotifications(),
    staleTime: 1000 * 60,
});

export const clearNotifications = async () => {
    const response = await httpClient.post(`/notifications/clear`);
    return response.data;
};

export const clearNotificationsMutation = () => ({
    mutationFn: async () => clearNotifications(),
});

export const clearSingleNotification = async (id: number) => {
    const response = await httpClient.post(`/notifications/read/${id}`);
    return response.data;
};

export const clearSingleNotificationMutation = () => ({
    mutationFn: async (id: number) => clearSingleNotification(id),
});

// FOR TEST PROPOSE ONLY
const createNotification = async () => {
    const response = await httpClient.post(`/notifications`, {
        title: 'test title',
        message: 'random text',
        recipient_id: '{{userId}}',
        redirect_uri: '/path/to/redirect',
    });
    return response.data;
};

export const createNotificationMutation = () => ({
    mutationFn: async () => createNotification(),
});
