import './app.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useEffect, lazy, Suspense } from 'react';
import {
    createBrowserRouter,
    RouterProvider,
    createRoutesFromElements,
    Route,
} from 'react-router-dom';

import Dimmer from 'Atoms/Dimmer';
import EventBestLapResults from 'Routes/explore/Events/EventBestLapResults';
import { appRoutes } from 'Routes/routesMap';
import { appGlobalCss } from 'Theme/reset';

const ErrorPage = lazy(() => import('Routes/general/errorPages'));
const DriverEvents = lazy(() => import('Organisms/DriverEvents'));
const DriverTracks = lazy(() => import('Organisms/DriverTracks'));
const AboutPage = lazy(() => import('Routes/about'));
const Account = lazy(() => import('Routes/account'));
const ActivityIndexPage = lazy(() => import('Routes/activity/index'));
const SessionUpload = lazy(() => import('Routes/activity/SessionUpload'));
const AccountActivation = lazy(() => import('Routes/auth/AccountActivation/AccountActivation'));
const AccountCreated = lazy(() => import('Routes/auth/AccountCreated/AccountCreated'));
const Auth = lazy(() => import('Routes/auth/Auth'));
const AuthBase = lazy(() => import('Routes/auth/AuthBase'));
const ForgotPassword = lazy(() => import('Routes/auth/ForgotPassword/ForgotPassword'));
const ResetPassword = lazy(() => import('Routes/auth/ResetPassword/ResetPassword'));
const AuthenticatedBase = lazy(() => import('Routes/AuthenticatedBase'));
const DriversAndCoaches = lazy(() => import('Routes/explore/DriversAndCoaches'));
const DriverSessions = lazy(() => import('Routes/explore/DriverSessions'));
const Events = lazy(() => import('Routes/explore/Events'));
const AddEvent = lazy(() => import('Routes/explore/Events/AddEvent'));
const EventDetails = lazy(() => import('Routes/explore/Events/EventDetails'));
const ExploreIndex = lazy(() => import('Routes/explore/ExploreIndex'));
const SelectedUserDetails = lazy(() => import('Routes/explore/selectedUserDetails'));
const SessionDetails = lazy(() => import('Routes/explore/session/SessionDetails'));
const TracksList = lazy(() => import('Routes/explore/Tracks'));
const AddLayout = lazy(() => import('Routes/explore/Tracks/AddLayout'));
const AddTrack = lazy(() => import('Routes/explore/Tracks/AddTrack'));
const TrackDetails = lazy(() => import('Routes/explore/Tracks/TrackDetails'));
const TrackLayoutDetails = lazy(() => import('Routes/explore/Tracks/TrackLayoutDetails'));
const AddVehicle = lazy(() => import('Routes/explore/Vehilce/AddVehicle'));
const DriverVehicleDetail = lazy(() => import('Routes/explore/Vehilce/DriverVehicleDetail'));
const DriverVehiclesList = lazy(() => import('Routes/explore/Vehilce/DriverVehiclesList'));
const Notifications = lazy(() => import('Routes/general/notificationsPage'));
const Home = lazy(() => import('Routes/home'));
const RecommendAppPage = lazy(() => import('Routes/recommend'));
const SessionAnalyzer = lazy(() => import('Routes/sessionAnalyzer'));

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60 * 5,
            retry: 2,
            refetchOnWindowFocus: false,
        },
    },
});

const routes = createBrowserRouter(
    createRoutesFromElements(
        <>
            {/* Public routes */}
            <Route element={<AuthBase />}>
                <Route element={<Auth variant='signIn' />} index path={appRoutes.auth.login} />
                <Route element={<Auth variant='signUp' />} path={appRoutes.auth.register} />
                <Route element={<ForgotPassword />} path={appRoutes.auth.forgotPassword} />
                <Route element={<ResetPassword />} path={appRoutes.auth.resetPassword} />
                <Route element={<AccountCreated />} path={appRoutes.auth.accountCreated} />
                <Route element={<AccountActivation />} path={appRoutes.auth.accountActivation} />
            </Route>

            {/* Private routes */}
            <Route element={<AuthenticatedBase />}>
                {/* HOME  */}
                <Route element={<Home />} index path={appRoutes.home} />

                {/* EXPLORE */}
                <Route path={appRoutes.explore.index}>
                    <Route element={<ExploreIndex />} index />

                    <Route path={appRoutes.explore.tracks.index}>
                        <Route element={<TracksList />} index />
                        <Route element={<AddTrack />} path={appRoutes.explore.tracks.addTrack} />
                        <Route element={<AddLayout />} path={appRoutes.explore.tracks.addLayout} />
                        <Route element={<TrackDetails />} path={appRoutes.explore.tracks.details} />
                        <Route
                            element={<TrackLayoutDetails />}
                            index
                            path={appRoutes.explore.tracks.layoutDetailsTable}
                        />
                    </Route>

                    <Route path={appRoutes.explore.events.index}>
                        <Route element={<Events />} index />
                        <Route
                            element={<EventDetails />}
                            path={appRoutes.explore.events.eventDetails}
                        />
                        <Route
                            element={<EventBestLapResults />}
                            path={appRoutes.explore.events.eventBestLapResults}
                        />
                    </Route>

                    <Route path={appRoutes.explore.drivers.index}>
                        <Route element={<DriversAndCoaches />} index />
                        <Route
                            element={<SelectedUserDetails />}
                            path={appRoutes.explore.drivers.details}
                        />
                        <Route path={appRoutes.explore.drivers.vehicles.index}>
                            <Route element={<DriverVehiclesList />} index />
                            <Route
                                element={<DriverVehicleDetail />}
                                path={appRoutes.explore.drivers.vehicles.details}
                            />
                        </Route>
                        <Route element={<DriverEvents />} path={appRoutes.explore.drivers.events} />
                        <Route element={<DriverTracks />} path={appRoutes.explore.drivers.tracks} />
                        <Route
                            element={<DriverSessions />}
                            path={appRoutes.explore.drivers.sessions}
                        />
                    </Route>

                    <Route element={<SessionDetails />} path={appRoutes.explore.sessions} />
                </Route>

                {/* MY ACTIVITY  */}
                <Route path={appRoutes.activity.index}>
                    <Route element={<ActivityIndexPage />} index />

                    <Route path={appRoutes.activity.sessions.index}>
                        <Route element={<DriverSessions />} index />
                        <Route
                            element={<SessionUpload />}
                            path={appRoutes.activity.sessions.upload}
                        />
                        <Route
                            element={<SessionDetails />}
                            path={appRoutes.activity.sessions.details}
                        />
                    </Route>

                    <Route path={appRoutes.activity.vehicles.index}>
                        <Route element={<DriverVehiclesList />} index />
                        <Route
                            element={<DriverVehicleDetail />}
                            path={appRoutes.activity.vehicles.details}
                        />
                        <Route element={<AddVehicle />} path={appRoutes.activity.vehicles.add} />
                    </Route>

                    <Route path={appRoutes.activity.events.index}>
                        <Route element={<DriverEvents />} index />
                        <Route element={<AddEvent />} path={appRoutes.activity.events.add} />
                    </Route>

                    <Route path={appRoutes.activity.tracks.index}>
                        <Route element={<DriverTracks />} path={appRoutes.activity.tracks.index} />
                    </Route>
                </Route>

                {/* MY ACCOUNT  */}
                <Route path={appRoutes.account.index}>
                    <Route element={<Account />} index />
                </Route>

                {/* ABOUT  */}
                <Route path={appRoutes.about.index}>
                    <Route element={<AboutPage />} index />
                </Route>

                {/* RECOMMEND APP  */}
                <Route path={appRoutes.recommend.index}>
                    <Route element={<RecommendAppPage />} index />
                </Route>

                {/* GENERAL ROUTES  */}
                <Route path={appRoutes.general.notifications.index}>
                    <Route element={<Notifications />} index />
                </Route>

                <Route element={<SessionAnalyzer />} path={appRoutes.analyze.index} />
            </Route>

            <Route element={<ErrorPage />} path='*' />
            <Route element={<ErrorPage />} path={appRoutes.errors.index} />
        </>
    )
);

const App: React.FC = () => {
    appGlobalCss();

    useEffect(() => {
        document.title = import.meta.env.VITE_APP_TITLE;
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID || ''}>
                <Suspense fallback={<Dimmer active={true} />}>
                    <RouterProvider router={routes} />
                </Suspense>

                <ReactQueryDevtools initialIsOpen={false} />
            </GoogleOAuthProvider>
        </QueryClientProvider>
    );
};

export default Sentry.withProfiler(App);
