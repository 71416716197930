import Button from 'Atoms/Button';
import { Flex } from 'Atoms/Flex';
import Icon from 'Atoms/Icon';
import { useResolution } from 'Hooks/useResolution';
import { appRoutes } from 'Routes/routesMap';

import useStore from 'Store/store';

const MobileButtonsNav = () => {
    const { setIsMobileNavOpen, isMobileNavOpen } = useStore((state) => state.general);
    const { isSmallDesktopWidth } = useResolution();

    if (isSmallDesktopWidth) return null;

    return (
        <Flex align='start'>
            <Button
                hasIcon={false}
                isLink
                onClick={() => {
                    setIsMobileNavOpen(false);
                }}
                to={appRoutes.home}
                variant='transparent'
            >
                <Icon color='black' name='home' />
            </Button>
            <Button
                hasIcon={false}
                onClick={() => {
                    setIsMobileNavOpen(!isMobileNavOpen);
                }}
                variant='transparent'
            >
                <Icon color='black' name='menu' />
            </Button>
        </Flex>
    );
};

export default MobileButtonsNav;
