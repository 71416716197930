import { styled } from 'Theme/stitches.config';

export const Text = styled('p', {
    fontSize: '$16',
    lineHeight: '138%',

    defaultVariants: {
        size: 'default',
    },

    variants: {
        wordBreak: {
            true: {
                wordBreak: 'break-word',
            },
        },
        preWrap: {
            true: {
                whiteSpace: 'pre-wrap',
            },
        },
        noWrap: {
            true: {
                whiteSpace: 'nowrap',
            },
        },
        color: {
            white: {
                color: '$mainWhite',
            },
            gray: {
                color: '$mainGray',
            },
            error: {
                color: '$mainRed',
            },
            success: {
                color: '$mainGreen',
            },
            info: {
                color: '$activeBlue',
            },
            black: {
                color: '$mainBlack',
            },
            inherit: {
                color: 'inherit',
            },
            orange: {
                color: '$mainOrange',
            },
            violet: {
                color: '$mainViolet',
            },
        },
        weight: {
            400: {
                fontWeight: 400,
            },
            500: {
                fontWeight: 500,
            },
            600: {
                fontWeight: 600,
            },
            800: {
                fontWeight: 800,
            },
            900: {
                fontWeight: 900,
            },
        },
        size: {
            h1: {
                fontWeight: 900,
                fontSize: '$28',
                lineHeight: '114%',
                letterSpacing: '0.02em',
            },
            h2: {
                fontWeight: 800,
                fontSize: '$24',
                lineHeight: '114%',
                letterSpacing: '0.02em',
            },
            h3: {
                fontWeight: 900,
                fontSize: '$18',
                letterSpacing: '0.02em',
                lineHeight: '133%',
            },
            h4: {
                fontWeight: 800,
                fontSize: '$16',
                letterSpacing: '0.02em',
                lineHeight: '138%',
            },
            md: {
                fontSize: '$14',
                lineHeight: '129%',
            },
            sm: {
                fontSize: '$12',
                lineHeight: '133%',
            },
            default: {
                fontSize: '$16',
                lineHeight: '138%',
            },
        },
        align: {
            left: {
                textAlign: 'left',
            },
            center: {
                textAlign: 'center',
            },
            right: {
                textAlign: 'right',
            },
        },
        link: {
            true: {
                cursor: 'pointer',
                textDecoration: 'underline',
                color: '$mainBlack',
            },
        },
    },
});
