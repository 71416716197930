import { FC } from 'react';

import Icon from 'Atoms/Icon';
import { OverlayButton } from 'Organisms/MapLapOverlay/styles';

interface PlayButtonProps {
    isPlaying: boolean;
    startAnimation: () => void;
    stopAnimation: () => void;
}
const PlayButton: FC<PlayButtonProps> = ({ isPlaying, startAnimation, stopAnimation }) => {
    return (
        <OverlayButton firstPlayer={false} onClick={isPlaying ? stopAnimation : startAnimation}>
            {isPlaying ? 'Pause' : 'Play '}
            <Icon name={'play'} size={'sm'} />
        </OverlayButton>
    );
};

export default PlayButton;
