import { styled } from 'Theme/stitches.config';

export const Box = styled('div', {
    variants: {
        bg: {
            white: {
                borderRadius: '$6',
                px: '$24',
                py: '$22',
                background: '$mainWhite',
            },
        },
        cols: {
            2: {
                gap: '$24',
                display: 'grid',
                gridAutoFlow: 'column',
                gridAutoColumns: '1fr',
            },
        },
        isFullWidth: {
            true: {
                width: '100%',
            },
        },
    },
});
