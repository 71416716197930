import React from 'react';

import { AvatarGroupElement, AvatarGroupRoot } from './styles';
import Avatar, { AvatarProps } from '../Avatar';

interface AvatarGroupInterface {
    users: Array<AvatarProps>;
    /** if rtl is true ten avatars are render from right to left */
    rtl?: boolean;
}

const AvatarGroup: React.FC<AvatarGroupInterface> = ({ users, rtl }) => {
    const userLength = users.length;
    const numberOfUsersToDisplay = userLength > 3 ? `+${userLength - 3}` : `${users.length}`;
    let avatarsWithImgSrc = users.filter((u) => u.imgSrc).slice(0, 3);
    // Step 2: Check if there are less than 3 avatars.
    if (avatarsWithImgSrc.length < 3) {
        // Step 3: Find users without imgSrc and append them.
        const avatarsWithoutImgSrc = users.filter((u) => !u.imgSrc);
        const additionalAvatarsNeeded = 3 - avatarsWithImgSrc.length;
        avatarsWithImgSrc = avatarsWithImgSrc.concat(
            avatarsWithoutImgSrc.slice(0, additionalAvatarsNeeded)
        );
    }
    const avatarsToDisplay = avatarsWithImgSrc;

    const space = 16;

    const spacing = (index: number) => {
        return rtl
            ? { left: index * space, zIndex: index }
            : { right: (index + 1) * space, zIndex: -index };
    };

    return (
        <AvatarGroupRoot>
            {avatarsToDisplay.map(({ imgSrc, fallback, userName }, index) => (
                <AvatarGroupElement
                    key={`${index}${imgSrc}${fallback}${userName}`}
                    css={{
                        ...spacing(index),
                    }}
                >
                    <Avatar
                        fallback={fallback}
                        imgSrc={imgSrc}
                        round
                        size='small'
                        userName={userName}
                    />
                </AvatarGroupElement>
            ))}
            <AvatarGroupElement
                css={
                    rtl
                        ? {
                              left: avatarsToDisplay.length * space,
                              zIndex: avatarsToDisplay.length + 1,
                          }
                        : { right: 0 }
                }
            >
                <Avatar fallback='' fallBackFullText={numberOfUsersToDisplay} round size='small' />
            </AvatarGroupElement>
        </AvatarGroupRoot>
    );
};

export default AvatarGroup;
