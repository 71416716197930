import moment from 'moment';
import { useRef } from 'react';
import { Link } from 'react-router-dom';

import { useUserPanelNotifications } from './hooks/useUserPanelNotifications';
import { ButtonsWrapper, Decor, NotificationItem, NotificationWrapper } from './styles';
import notificationPlaceholder from 'Assets/images/notificationPlaceholder.png';
import Avatar from 'Atoms/Avatar';
import Button from 'Atoms/Button';
import BlackDotDivider from 'Atoms/Dividers/BlackDotDivider';
import { Flex } from 'Atoms/Flex';
import Icon from 'Atoms/Icon';
import { Text } from 'Atoms/Text';
import MessageBar from 'Molecules/MessageBar';
import { appRoutes } from 'Routes/routesMap';

const Notifications = () => {
    const dropdownRef = useRef<HTMLDivElement>(null);

    const {
        lastNotifications,
        areNotificationsOpen,
        setAreNotificationsOpen,
        newNotificationsAvailable,
        clearNotifications,
    } = useUserPanelNotifications({ dropdownRef });

    return (
        <div style={{ position: 'relative' }}>
            <Button
                css={{ padding: '0 4px', height: '38px' }}
                hasIcon={false}
                onClick={() => setAreNotificationsOpen(!areNotificationsOpen)}
                variant='transparent'
            >
                {newNotificationsAvailable ? (
                    <span style={{ marginBottom: '4px', display: 'block' }}>
                        <Icon name='active-Bell' />
                    </span>
                ) : (
                    <Icon color='black' name='bell' />
                )}
            </Button>
            {areNotificationsOpen && (
                <NotificationWrapper ref={dropdownRef} direction='column'>
                    {!lastNotifications?.length && (
                        <MessageBar
                            centerText
                            fullWidth
                            message='You have no notifications.'
                            variant='info'
                        />
                    )}
                    {lastNotifications?.map(
                        ({ title, message, created_at, read, image_url }, index) => {
                            return (
                                <Flex key={index} direction='column'>
                                    <NotificationItem
                                        align='center'
                                        as={Link}
                                        gap='lg'
                                        to={appRoutes.general.notifications.index}
                                    >
                                        <Avatar
                                            active={!read}
                                            fallback=''
                                            imgSrc={image_url || notificationPlaceholder}
                                        />
                                        <Flex direction='column' isFullWidth>
                                            <Flex align='center'>
                                                <Text size='md' weight='500'>
                                                    {title.length > 18
                                                        ? `${title.substring(0, 18)}...`
                                                        : title}
                                                </Text>
                                                <BlackDotDivider />
                                                <Text color='gray' noWrap size='sm'>
                                                    {moment(created_at)
                                                        .startOf('minutes')
                                                        .fromNow()}
                                                </Text>
                                            </Flex>
                                            <Text color='gray' size='sm'>
                                                {message.length > 40
                                                    ? `${message.substring(0, 40)}...`
                                                    : message}
                                            </Text>
                                        </Flex>
                                    </NotificationItem>
                                    {index !== lastNotifications.length - 1 && <Decor />}
                                </Flex>
                            );
                        }
                    )}
                    <Decor />
                    <ButtonsWrapper justify='space-around'>
                        <Button
                            hasIcon={false}
                            onClick={() => clearNotifications()}
                            variant='transparent'
                        >
                            <Text as={'span'} color='black' size='md'>
                                Clear all
                            </Text>
                        </Button>
                        <Button
                            hasIcon={false}
                            isLink
                            to={appRoutes.general.notifications.index}
                            variant='transparent'
                        >
                            <Text as={'span'} color='info' size='md'>
                                See all
                            </Text>
                        </Button>
                    </ButtonsWrapper>
                </NotificationWrapper>
            )}
        </div>
    );
};

export default Notifications;
