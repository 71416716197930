import { StateCreator } from 'zustand';

import { StoreType } from './store';

export type VehicleSlice = {
    isVehicleDetailsEditorOpen: boolean;
    setIsVehicleDetailsEditorOpen: (value: boolean) => void;
    isOpenVehicleEditorByCard: boolean;
    setIsOpenVehicleEditorByCard: (value: boolean) => void;
};

export const vehicleSlice: StateCreator<
    StoreType,
    [['zustand/immer', never], ['zustand/devtools', never]],
    [],
    VehicleSlice
> = (set) => ({
    isVehicleDetailsEditorOpen: false,
    setIsVehicleDetailsEditorOpen: (value) =>
        set(
            (state) => {
                state.vehicle.isVehicleDetailsEditorOpen = value;
            },
            false,
            'setIsVehicleDetailsEditorOpen'
        ),

    isOpenVehicleEditorByCard: false,
    setIsOpenVehicleEditorByCard: (value) =>
        set(
            (state) => {
                state.vehicle.isOpenVehicleEditorByCard = value;
            },
            false,
            'setIsOpenVehicleEditorByCard'
        ),
});
