import { Flex } from 'Atoms/Flex';
import { styled } from 'Theme/stitches.config';

export const NotificationWrapper = styled(Flex, {
    position: 'absolute',
    right: -53,
    width: '310px',
    maxHeight: '500px',
    backgroundColor: '$mainWhite',
    borderRadius: '$4',
    padding: '16px 24px',
    zIndex: 100,
    border: '1px solid $lightGray',
    boxShadow: '$default',

    '@full_hd': {
        width: '370px',
    },
});

export const Decor = styled('div', {
    width: '100%',
    height: '2px',
    borderRadius: '$4',
    backgroundColor: '$lightGray',
    margin: '16px 0',
});

export const NotificationItem = styled(Flex, {
    color: '$mainBlack',
});

export const ButtonsWrapper = styled(Flex, {
    '& button, & a': {
        padding: 0,
    },
});
