import { StateCreator } from 'zustand';

import {
    ChannelProps,
    TimelineProps,
    // defaultSelectedTime,
    // SessionTime,
    TimerStatusModel,
    LapsToCompareModel,
} from './models';
import { StoreType } from '../store';

export type AnalyzerSlice = {
    timeline: TimelineProps;
    setTimeline: (value: TimelineProps) => void;

    selectedLap?: number;
    setSelectedLap: (value: number) => void;

    sessions: string[];
    setSessions: (value: string[]) => void;

    selectedChannels?: ChannelProps[];
    setSelectedChannels: (value: ChannelProps[]) => void;

    // sessionTimes: Map<string, SessionTime>;
    // addSessionTime: (sessionId: string, time: SessionTime) => void;

    compareSessionLap: Map<string, number>;
    setCompareSessionLap: (sessionId: string, lapNumber: number) => void;

    // PLAYER

    timerStatus: TimerStatusModel;
    setTimerStatus: (status: TimerStatusModel) => void;

    timerValue: number;
    setTimerValue: (value: number) => void;

    updateTimer: number;
    setUpdateTimer: (value: number) => void;

    //  SESSIONS COMPARISON

    selectedSessionsToCompare: string[];
    setSelectedSessionsToCompare: (sessions: string[]) => void;

    userLapsToCompare: string[];
    setUserLapsToCompare: (sessions: string[]) => void;

    otherDriversLapsToCompare: LapsToCompareModel[];
    setOtherDriversLapsToCompare: (sessions: LapsToCompareModel[]) => void;
};

export const analyzerSlice: StateCreator<
    StoreType,
    [['zustand/immer', never], ['zustand/devtools', never]],
    [],
    AnalyzerSlice
> = (set) => ({
    timeline: {
        initialStart: undefined,
        initialFinish: undefined,
        selectedFinish: undefined,
        selectedStart: undefined,
    },
    setTimeline: (value) =>
        set(
            (state) => {
                state.analyzer.timeline = value;
            },
            false,
            'setTimeline'
        ),

    selectedLap: undefined,
    setSelectedLap: (value) =>
        set(
            (state) => {
                state.analyzer.selectedLap = value;
            },
            false,
            'setSelectedLap'
        ),

    sessions: [],
    setSessions: (value) =>
        set(
            (state) => {
                state.analyzer.sessions = value;
            },
            false,
            'setSessions'
        ),

    selectedChannels: undefined,
    setSelectedChannels: (value) =>
        set(
            (state) => {
                state.analyzer.selectedChannels = value;
            },
            false,
            'setSelectedChannels'
        ),

    // sessionTimes: new Map<string, SessionTime>(),
    // addSessionTime: (sessionId: string, time: SessionTime) =>
    //     set(
    //         (state) => {
    //             state.analyzer.sessionTimes.set(sessionId, time);
    //         },
    //         false,
    //         'setSessionTimes'
    //     ),

    compareSessionLap: new Map<string, number>(),
    setCompareSessionLap: (sessionId: string, lapNumber: number) =>
        set(
            (state) => {
                state.analyzer.compareSessionLap.set(sessionId, lapNumber);
            },
            false,
            'setCompareSessionLap'
        ),

    // PLAYER
    timerStatus: TimerStatusModel.Idle,
    setTimerStatus: (status) =>
        set(
            (state) => {
                state.analyzer.timerStatus = status;
            },
            false,
            'setTimerStatus'
        ),

    timerValue: 0,
    setTimerValue: (value) =>
        set(
            (state) => {
                state.analyzer.timerValue = value;
            },
            false,
            'setTimerValue'
        ),

    updateTimer: 0,
    setUpdateTimer: (value) =>
        set(
            (state) => {
                state.analyzer.updateTimer = value;
            },
            false,
            'setUpdateTimer'
        ),

    selectedSessionsToCompare: [],
    setSelectedSessionsToCompare: (value) =>
        set(
            (state) => {
                state.analyzer.selectedSessionsToCompare = value;
            },
            false,
            'setSelectedSessionsToCompare'
        ),

    userLapsToCompare: [],
    setUserLapsToCompare: (value) =>
        set(
            (state) => {
                state.analyzer.userLapsToCompare = value;
            },
            false,
            'setUserLapsToCompare'
        ),

    otherDriversLapsToCompare: [],
    setOtherDriversLapsToCompare: (value) =>
        set(
            (state) => {
                state.analyzer.otherDriversLapsToCompare = value;
            },
            false,
            'setOtherDriversLapsToCompare'
        ),
});
