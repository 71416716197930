import { httpClient } from 'Infrastructure/http/client';
import { MapLaps } from 'Organisms/MapLapOverlay/overlay_lap';

import { SESSION_LAP_POSITIONS_KEY } from 'Services/queryKeys';

const convertLapsToQueryString = (laps: { sessionId: string; lapNumber: number }[]): string => {
    const queryParts = laps.flatMap((lap) => [
        `session[]=${encodeURIComponent(lap.sessionId)}`,
        `lap[]=${encodeURIComponent(lap.lapNumber)}`,
    ]);

    return queryParts.join('&');
};
export const getSessionLapPositions = async (laps: MapLaps) => {
    const response = await httpClient.get(
        `/analyze/session/position?${convertLapsToQueryString(laps)}`
    );
    return response.data;
};

export const getSessionLapPositionsQuery = (laps: MapLaps) => ({
    queryKey: [SESSION_LAP_POSITIONS_KEY, laps],
    queryFn: async () => getSessionLapPositions(laps),
});
