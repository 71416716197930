import { CSS } from '@stitches/react';
import { FC } from 'react';
import IcoMoon, { IconProps } from 'react-icomoon';

import { IconWrapper } from './styles';
import iconSet from 'Icons/selection.json';

interface CustomIconProps extends Omit<IconProps, 'icon'> {
    name: IconName;
    color?: string;
    withBackground?: boolean;
    backgroundColor?: 'transparent' | 'blurred';
    direction?: 'left' | 'top' | 'bottom';
    onClick?: () => void;
    css?: CSS;
}

const Icon: FC<CustomIconProps> = ({
    name,
    size = 24,
    color,
    withBackground,
    backgroundColor,
    direction,
    onClick,
    css,
}) => (
    <IconWrapper
        backgroundColor={backgroundColor}
        css={css}
        direction={direction}
        onClick={onClick}
        withBackground={withBackground}
    >
        <IcoMoon color={color} icon={name} iconSet={iconSet} size={size} />
    </IconWrapper>
);

export default Icon;

// INFO: use this consoe log to generate types - iconList is imported from react-icomoon
// console.log(`"${iconList(iconSet)}"`.replaceAll(',', `${'" | "'}`));

export type IconName =
    | 'active-Bell'
    | 'alert'
    | 'arrow'
    | 'arrow-button'
    | 'arrow-button-shadow'
    | 'arrow-light'
    | 'arrow-right'
    | 'bell'
    | 'blue-check'
    | 'calendar-days'
    | 'camera'
    | 'car'
    | 'chart'
    | 'chart-bar'
    | 'chart-line'
    | 'check'
    | 'clock'
    | 'close'
    | 'delete'
    | 'dotDivider'
    | 'drag-indicator'
    | 'edit'
    | 'email'
    | 'envelope'
    | 'equalizer'
    | 'event'
    | 'eye'
    | 'eye-off'
    | 'facebook'
    | 'facebook-blue'
    | 'facebook-circle'
    | 'fb'
    | 'file'
    | 'file-add'
    | 'file-edit'
    | 'format-line-spacing'
    | 'fullscreen'
    | 'fullscreen-exit'
    | 'gmail-red'
    | 'google'
    | 'google-plus'
    | 'gps'
    | 'hammer-screwdriver'
    | 'heart'
    | 'home'
    | 'info'
    | 'instagram'
    | 'linkedin'
    | 'log-out'
    | 'map'
    | 'medal-outline'
    | 'menu'
    | 'menu-2'
    | 'more'
    | 'phone'
    | 'photo'
    | 'photo-add'
    | 'photos'
    | 'play'
    | 'plus'
    | 'road-variant'
    | 'school-outline'
    | 'session'
    | 'shadowed-arrow-blue'
    | 'speedometer'
    | 'square-shadow'
    | 'squares'
    | 'star'
    | 'time2'
    | 'time3'
    | 'track'
    | 'trending-up'
    | 'trophy'
    | 'twitter-bg'
    | 'twitter-bird'
    | 'twitter-x'
    | 'user'
    | 'vertical-align-bottom'
    | 'vertical-align-top'
    | 'web'
    | 'youtube';
