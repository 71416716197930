import { styled } from 'Theme/stitches.config';

export const IconWrapper = styled('span', {
    display: 'grid',
    placeItems: 'center',
    flexShrink: 0,

    variants: {
        withBackground: {
            true: {
                width: '38px',
                height: '38px',
                backgroundColor: '$lightGray',
                borderRadius: '$4',
            },
        },
        backgroundColor: {
            transparent: {
                backgroundColor: 'transparent',
            },
            blurred: {
                backgroundColor: '#5881dd',
            },
        },
        direction: {
            left: {
                transform: 'rotate(180deg)',
            },
            bottom: {
                transform: 'rotate(90deg)',
            },
            top: {
                transform: 'rotate(270deg)',
            },
        },
    },
});
