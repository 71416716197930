import { FC } from 'react';

import Avatar from 'Atoms/Avatar';
import { useLoggedUserData } from 'Helpers/useLoggedUserData';
import { appRoutes } from 'Routes/routesMap';

interface LoggedUserAvatarProps {
    size?: 'small' | 'normal' | 'large';
}

const LoggedUserAvatar: FC<LoggedUserAvatarProps> = ({ size }) => {
    const { userData } = useLoggedUserData();

    return (
        <Avatar
            fallback={`${userData?.first_name} ${userData?.last_name || ''}`}
            imgSrc={userData?.avatar}
            linkTo={appRoutes.activity.index}
            size={size}
            userName={userData?.first_name}
        />
    );
};

export default LoggedUserAvatar;
