import { globalCss } from './stitches.config';
import AvenirHeavy from 'Fonts/avenir-heavy.ttf';
import AvenirMedium from 'Fonts/avenir-medium.ttf';
import Avenir from 'Fonts/avenir.ttf';

const resetCss = {
    '*': {
        boxSizing: 'border-box',
        fontFamily: '$avenir',
    },
    'html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby, section, summary, time, mark, audio, video':
        {
            margin: '0',
            padding: '0',
            border: '0',
            fontSize: '100%',
            font: 'inherit',
            verticalAlign: 'baseline',
        },
    'article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section':
        {
            display: 'block',
        },
    '*[hidden]': {
        display: 'none',
    },
    body: {
        lineHeight: '1',
    },
    'ol, ul': {
        listStyle: 'none',
    },
    'blockquote, q': {
        quotes: 'none',
    },
    'blockquote:before, blockquote:after, q:before, q:after': {
        content: '',
    },
    table: {
        borderSpacing: '0',
    },
};

const global = {
    body: {
        background: '$backgroundGray',
        fontFamily: '$avenir',
        color: '$mainBlack',
        fontSize: '$14',
    },

    a: {
        textDecoration: 'none',
        color: '$mainBlue',
        '&:hover': {
            color: '$activeBlue',
        },
    },
    ol: {
        listStyle: 'list-counter',
        paddingLeft: '1.5em',
        marginBottom: '1em',
        li: {
            position: 'relative',
            marginBottom: '0.5em',
        },
    },
    ul: {
        listStyle: 'list-counter',
        paddingLeft: '1.5em',
        marginBottom: '1em',
        li: {
            position: 'relative',
            marginBottom: '0.5em',
        },
    },
    '@font-face': [
        {
            fontFamily: 'Avenir',
            fontWeight: 'normal',
            src: `local('Avenir'), url(${Avenir}) format('truetype')`,
            fontDisplay: 'swap',
        },
        {
            fontFamily: 'Avenir',
            fontWeight: 500,
            src: `local('Avenir'), url(${AvenirMedium}) format('truetype')`,
            fontDisplay: 'swap',
        },
        {
            fontFamily: 'Avenir',
            fontWeight: 'bold',
            src: `local('AvenirHeavy'), url(${AvenirHeavy}) format('truetype')`,
            fontDisplay: 'swap',
        },
    ],
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const appGlobalCss = globalCss(resetCss, global);
