import { EVENT_LEADERBOARD_KEY } from './queryKeys';
import { ApiResponse } from './response';
import { httpClient } from 'Infrastructure/http/client';

export interface EventLeaderboardResult {
    position: number;
    session_id: string;
    vehicle_id: string;
    best_lap_number: number;
    best_lap_time: string;
    vehicle_name: string;
    vehicle_type: 'car' | 'motorcycle';
    driver: {
        id: string;
        name: string;
        avatar: string;
    };
    updated: string;
}

export const getEventLeaderboard = async (
    id: string
): Promise<ApiResponse<EventLeaderboardResult[]>> => {
    const response = await httpClient.get(`/events/${id}/leaderboard`);
    return response.data;
};

export const getEventLeaderboardQuery = (id: string) => ({
    queryKey: [EVENT_LEADERBOARD_KEY, id],
    queryFn: () => getEventLeaderboard(id),
});
