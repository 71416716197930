import { styled } from 'Theme/stitches.config';

export const StyledButton = styled('button', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pad: '$16',
    color: '$white',
    border: 0,
    borderRadius: '$4',
    cursor: 'pointer',
    fontWeight: 500,
    fontSize: '$16',
    textAlign: 'left',
    gap: '$8',

    '&:hover': {
        backgroundColor: '$mainBlack',
        color: '$mainWhite',
    },

    '&:disabled': {
        backgroundColor: '$mainGray',
        cursor: 'default',
        opacity: '30%',
        pointerEvents: 'none',
        color: '$white',
    },

    variants: {
        variant: {
            primaryBlue: {
                backgroundColor: '$activeBlue',
            },
            primaryRed: {
                backgroundColor: '$mainRed',

                '&:hover': {
                    backgroundColor: '$secondaryRed',
                    opacity: 0.8,
                },
            },
            primaryBlack: {
                backgroundColor: '$black',
                border: '1px solid $white',

                '&:hover': {
                    border: '1px solid $mainBlue',
                },
            },
            secondaryGray: {
                backgroundColor: '$lightGray',
                color: '$mainBlack',
            },
            transparent: {
                backgroundColor: 'transparent',

                '&:hover': {
                    backgroundColor: 'transparent',
                    opacity: '0.5',
                },
            },
            transparentBordered: {
                backgroundColor: 'transparent',
                border: '1px solid $activeBlue',
                color: '$activeBlue',

                '&:hover': {
                    backgroundColor: 'transparent',
                    opacity: '0.5',
                    color: '$activeBlue',
                },
            },
            secondaryBlue: {
                backgroundColor: 'rgba(254, 254, 254, 0.2)',
            },
        },
        centerText: {
            true: {
                textAlign: 'center',
            },
        },

        size: {
            xs: {
                fontSize: '8px',
                padding: '5px 8px',
            },
            sm: {
                fontSize: '$12',
                pad: '6px 10px',
            },
            md: {
                fontSize: '$14',
                pad: '10px 14px',
            },
            lg: {
                fontSize: '$16',
            },
            xl: {
                fontSize: '$18',
                padding: '$18',
            },
        },
    },
});
