import { createStitches, PropertyValue } from '@stitches/react';

export const FULL_HD = 1440;
export const DESKTOP = 1180;
export const SMALL_DESKTOP = 768;
export const TABLET = 600;

export const { styled, globalCss, config } = createStitches({
    theme: {
        fonts: {
            avenir: 'Avenir',
            untitled: 'Untitled Sans, apple-system, sans-serif',
            mono: 'Söhne Mono, menlo, monospace',
        },
        fontSizes: {
            12: '12px',
            14: '14px',
            16: '16px',
            18: '18px',
            24: '24px',
            28: '28px',
        },
        colors: {
            //blue
            mainBlue: 'rgba(46, 97, 212, .8)',
            activeBlue: '#2E61D4',
            activeBlueBackground: '#EBF0FB',
            bb: '#CBD5EF',
            infoBlue: '#2e61d41a',

            //violet
            mainViolet: '#9100EA',

            //black
            black: '#000',
            mainBlack: '#070709',
            mainBlackBackground: '#E7E7E7',

            //gray
            backgroundGray: '#E5E5E5',
            componentBackground: '#F2F2F5',
            mainGray: '#4E4E61',
            mainGray08: 'rgba(78, 78, 97, .08)',
            lightGray: '#F6F6F7',
            darkGray: '#838383',

            //white
            white: '#fff',
            mainWhite: '#FEFEFE',

            //green
            mainGreen: '#11A532',
            mainGreenBackground: '#E8F6EB',
            mainLightGreenBackground: '#E6F5EA',

            //yellow
            mainYellow: '#FFBF42',

            //orange
            mainOrange: '#F09000',

            //red
            mainRed: '#C70712',
            secondaryRed: '#C9111B',
            backgroundError: '#EEDBDF',
            mainLightRedBackground: '#F9E5E6',
        },
        space: {
            2: '2px',
            4: '4px',
            8: '8px',
            10: '10px',
            12: '12px',
            14: '14px',
            16: '16px',
            18: '18px',
            20: '20px',
            22: '22px',
            24: '24px',
            28: '28px',
            32: '32px',
            48: '48px',
            80: '80px',
        },
        radii: {
            0: 'none',
            2: '2px',
            4: '4px',
            6: '6px',
        },
        shadows: {
            default: '5px 3px 24px -11px rgba(66, 68, 90, 1)',
        },
    },
    media: {
        mobile: '(min-width: 0)',
        tablet: `(min-width: ${TABLET}px)`,
        smallDesktop: `(min-width: ${SMALL_DESKTOP}px)`,
        desktop: `(min-width: ${DESKTOP}px)`,
        full_hd: `(min-width: ${FULL_HD}px)`,
    },
    utils: {
        m: (value: PropertyValue<'margin'>) => ({
            margin: value,
        }),
        mt: (value: PropertyValue<'marginTop'>) => ({
            marginTop: value,
        }),
        mr: (value: PropertyValue<'marginRight'>) => ({
            marginRight: value,
        }),
        mb: (value: PropertyValue<'marginBottom'>) => ({
            marginBottom: value,
        }),
        ml: (value: PropertyValue<'marginLeft'>) => ({
            marginLeft: value,
        }),
        mx: (value: PropertyValue<'marginLeft'>) => ({
            marginLeft: value,
            marginRight: value,
        }),
        my: (value: PropertyValue<'marginBottom'>) => ({
            marginTop: value,
            marginBottom: value,
        }),
        pad: (value: PropertyValue<'padding'>) => ({
            padding: value,
        }),
        pt: (value: PropertyValue<'paddingTop'>) => ({
            paddingTop: value,
        }),
        pr: (value: PropertyValue<'paddingRight'>) => ({
            paddingRight: value,
        }),
        pb: (value: PropertyValue<'paddingBottom'>) => ({
            paddingBottom: value,
        }),
        pl: (value: PropertyValue<'paddingLeft'>) => ({
            paddingLeft: value,
        }),
        px: (value: PropertyValue<'paddingLeft'>) => ({
            paddingLeft: value,
            paddingRight: value,
        }),
        py: (value: PropertyValue<'paddingBottom'>) => ({
            paddingTop: value,
            paddingBottom: value,
        }),
        hexa: (value: Record<string, string | undefined>) => value,
    },
});
