import { Text } from 'Atoms/Text';
import { styled } from 'Theme/stitches.config';

export const EventResultRowWrapper = styled('div', {
    display: 'flex',
    backgroundColor: '$mainWhite',
    borderRadius: '$4',
    padding: '$14',
    width: '100%',
    gap: '$8',

    border: '2px solid transparent',

    variants: {
        selected: {
            true: {
                borderColor: '$mainOrange',
                pointerEvents: 'none',
            },
        },
        highlighted: {
            true: {
                borderColor: '$mainViolet',
                pointerEvents: 'none',
            },
        },
        isClickable: {
            true: {
                cursor: 'pointer',
            },
        },
        visible: {
            true: {
                display: 'flex',
            },
            false: {
                display: 'none',
            },
        },
    },
});
export const Position = styled('div', {
    flex: '0 0 44px',
    display: 'flex',
    alignItems: 'center',
});

export const PositionText = styled(Text, {
    fontSize: '$18',
    textAlign: 'right',
    minWidth: '32px',
});

export const Driver = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
});

export const DriverName = styled(
    Text,
    {},
    {
        defaultVariants: {
            size: 'md',
            color: 'black',
        },
    }
);

export const VehicleName = styled(
    Text,
    {},
    {
        defaultVariants: {
            size: 'sm',
            color: 'gray',
        },
    }
);

export const Result = styled('div', {
    ml: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
});

export const ResultTime = styled(
    Text,
    {},
    {
        defaultVariants: {
            size: 'md',
            color: 'black',
        },
    }
);
