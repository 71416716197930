import { Root, Image, Fallback } from '@radix-ui/react-avatar';

import { styled } from 'Theme/stitches.config';

export const AvatarRoot = styled(Root, {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'middle',
    overflow: 'hidden',
    userSelect: 'none',

    borderRadius: 4,

    backgroundColor: '$bb',
    color: '$activeBlue',
    fontWeight: 'bold',

    variants: {
        size: {
            small: {
                width: 32,
                height: 32,
            },
            normal: {
                width: 38,
                height: 38,
            },
            large: {
                width: 50,
                height: 50,
            },
            extraLarge: {
                width: 80,
                height: 80,
            },
        },
        round: {
            true: {
                borderRadius: '100%',
            },
        },
    },
    defaultVariants: {
        size: 'normal',
    },
});

export const AvatarImage = styled(Image, {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 'inherit',
});

export const AvatarFallback = styled(Fallback, {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '$bb',
    color: '$activeBlue',
    fontSize: 11,
    lineHeight: 1,
    fontWeight: 800,
});

export const ActiveGreenDot = styled('div', {
    position: 'absolute',
    right: '-4px',
    top: '-4px',
    borderRadius: '50%',
    width: '12px',
    height: '12px',
    backgroundColor: '$white',
    zIndex: 1,

    '&::after': {
        position: 'absolute',
        content: '',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '50%',
        width: '8px',
        height: '8px',
        backgroundColor: '#11A532',
    },
});
