import { useQuery } from '@tanstack/react-query';
import { MapProps } from '@vis.gl/react-google-maps';
import { FC } from 'react';

import Map from 'Molecules/Map';
import { MapLapOverlayContextWrapper } from 'Organisms/MapLapOverlay/context';
import MapOverlay, { MapLaps } from 'Organisms/MapLapOverlay/overlay_lap';

import {
    getTrackLayoutMetadataQuery,
    TrackLayoutMetadataResponse,
} from 'Services/exploreQueries';

const maxLat = (Math.atan(Math.sinh(Math.PI)) * 180) / Math.PI;

interface MapLapOverlayProps {
    laps: MapLaps;
    mapCenter: google.maps.LatLngLiteral;
    mapZoom?: number;
    mapHeight?: string;
    trackLayoutId?: number;
}

const MapLapOverlay: FC<MapLapOverlayProps> = ({ laps, mapZoom, mapCenter , trackLayoutId}) => {
    const mapOptions: MapProps = {
        defaultCenter: mapCenter,
        mapId: 'aa33b1ed2b148dab',
        scrollwheel: false,
        defaultZoom: mapZoom || 15,
        restriction: {
            latLngBounds: { north: maxLat, south: -maxLat, west: -180, east: 180 },
            strictBounds: true,
        },
        streetViewControl: false,
        mapTypeControlOptions: {
            mapTypeIds: ['satellite', 'roadmap'],
        },
        mapTypeId: 'satellite',
    };


    const { data: trackLayout } = useQuery<TrackLayoutMetadataResponse>({
        ...getTrackLayoutMetadataQuery({ layoutId: trackLayoutId! }),
        enabled: !!trackLayoutId,
    });

    return (
        <MapLapOverlayContextWrapper>
            <MapOverlay laps={laps} startFinishLine={trackLayout}>
                <Map {...mapOptions} />
            </MapOverlay>
        </MapLapOverlayContextWrapper>
    );
};

export default MapLapOverlay;
