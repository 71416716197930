import { Flex } from 'Atoms/Flex';
import { styled } from 'Theme/stitches.config';

export const StyledContainer = styled('div', {
    height: '100%',
    minHeight: '100vh',
    margin: '0 auto',
});

export const Content = styled('div', {
    display: 'flex',
    height: '100%',
    minHeight: '100vh',
    alignItems: 'stretch',
    overflow: 'hidden',
    padding: '16px',

    '@tablet': { padding: 0 },
});

export const TopBarWrapper = styled(Flex, {
    paddingBottom: '$12',

    '@desktop': {
        minHeight: '80px',
        paddingBottom: '4px',
    },
});
