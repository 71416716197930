import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState, useEffect, useMemo } from 'react';

import {
    NotificationData,
    clearNotificationsMutation,
    getNotificationsQuery,
} from 'Services/notificationsQueries';
import { NOTIFICATIONS_KEY } from 'Services/queryKeys';
import { ApiResponse } from 'Services/response';

interface UseUserPanelNotificationsProps {
    dropdownRef: React.RefObject<HTMLDivElement>;
}

export const useUserPanelNotifications = ({ dropdownRef }: UseUserPanelNotificationsProps) => {
    const queryClient = useQueryClient();
    const [areNotificationsOpen, setAreNotificationsOpen] = useState(false);

    const { data: notifications } =
        useQuery<ApiResponse<NotificationData[]>>(getNotificationsQuery());

    const { mutate: clearNotifications } = useMutation({
        ...clearNotificationsMutation(),
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: [NOTIFICATIONS_KEY] });
        },
    });

    const newNotificationsAvailable = useMemo(
        () => notifications?.some((item) => item.read === false),
        [notifications]
    );
    const lastNotifications = useMemo(() => notifications?.slice(0, 3), [notifications]);

    const detectClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node))
            setAreNotificationsOpen(false);
    };

    useEffect(() => {
        window.addEventListener('click', detectClickOutside, true);
        return () => window.removeEventListener('click', detectClickOutside, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        lastNotifications,
        areNotificationsOpen,
        setAreNotificationsOpen,
        newNotificationsAvailable,
        clearNotifications,
    };
};
