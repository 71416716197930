import { useQuery } from '@tanstack/react-query';
import { FC, useState } from 'react';

import Avatar from 'Atoms/Avatar';
import AvatarGroup from 'Atoms/AvatarGroup';
import { Column, Flex } from 'Atoms/Flex';
import { Text } from 'Atoms/Text';
import { TopBarWrapper } from 'Components/global/styles';
import { MainColumnAside, MainColumnContent } from 'Components/Layout';
import { displayFullDate } from 'Helpers/dates';
import { capitalizeFirstLetter } from 'Helpers/strings';
import { useResolution } from 'Hooks/useResolution';
import EventBestLapResultRow from 'Molecules/EventBestLapResultRow';
import MapWrapper from 'Molecules/MapWrapper';
import MapLapOverlay from 'Organisms/MapLapOverlay';
import UserPanel from 'Organisms/UserPanel';
import { CompareBox, ResultsWrapper } from 'Routes/explore/Events/EventBestLapResults/styles';
import { useEventsDetails } from 'Routes/explore/Events/hooks/useEventDetails';

import { getEventLeaderboardQuery } from 'Services/leaderboard';

const EventBestLapResults: FC = () => {
    const { isDesktopWidth } = useResolution();
    const { eventTrack, convertedUsersList, eventDetail } = useEventsDetails();
    const [selectedLapPositions, setSelectedLapPositions] = useState([1, 2]);
    const eventId = eventDetail?.id;
    const { data: eventLeaderboardResults } = useQuery({
        ...getEventLeaderboardQuery(eventId ?? ''),
        enabled: !!eventId,
    });

    const selectedLapResults = eventLeaderboardResults?.filter((result) =>
        selectedLapPositions.includes(result.position)
    );
    const firstSelectedResult = selectedLapResults?.find(
        (result) => result.position === selectedLapPositions[0]
    );
    const secondSelectedResult = selectedLapResults?.find(
        (result) => result.position === selectedLapPositions[1]
    );

    const selectedLaps =
        selectedLapResults?.map(({ session_id: sessionId, best_lap_number: lapNumber }) => ({
            lapNumber,
            sessionId,
        })) || [];

    const handleSelectResult = (position: number) => {
        if (selectedLapPositions.length < 2) {
            setSelectedLapPositions((prev) => [...prev, position]);
        } else {
            setSelectedLapPositions([position]);
        }
    };

    const subtitle = `${capitalizeFirstLetter(eventDetail?.address.city ?? '')}, ${
        eventDetail?.address.country.name
    } - ${displayFullDate(eventDetail?.starts ?? '')}`;

    if (!eventTrack || !eventDetail) {
        return null;
    }

    return (
        <>
            <MainColumnContent>
                <TopBarWrapper direction='column'>
                    <Flex align='center' isFullWidth justify='space-between'>
                        <Flex>
                            <Text as='h1' preWrap size='h1'>
                                {eventDetail?.name}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex justify='space-between'>
                        <Text>{subtitle}</Text>
                        {!!convertedUsersList?.length && <AvatarGroup users={convertedUsersList} />}
                    </Flex>
                </TopBarWrapper>
                <Column gap='lg'>
                    <Flex direction='column' gap='md'>
                        <Text size='md' weight='600'>
                            Analyse results on map
                        </Text>
                        <div style={{ height: 500 }}>
                            <MapWrapper key={selectedLapPositions.join('')}>
                                <MapLapOverlay
                                    laps={selectedLaps}
                                    mapCenter={{
                                        lat: eventTrack?.location?.latitude,
                                        lng: eventTrack?.location?.longitude,
                                    }}
                                />
                            </MapWrapper>
                        </div>
                    </Flex>
                    <CompareBox>
                        <Flex align='center' gap='lg' justify='end'>
                            <Column gap='md'>
                                <Text align='right' color='orange' size='h2'>
                                    {firstSelectedResult?.best_lap_time ?? '-'}
                                </Text>
                                <Text align='right' size='h3' weight='400'>
                                    {firstSelectedResult?.driver.name ?? '-'}
                                </Text>
                            </Column>
                            <Avatar
                                fallback={firstSelectedResult?.driver.name ?? '-'}
                                imgSrc={firstSelectedResult?.driver.avatar}
                                size='extraLarge'
                            />
                        </Flex>
                        <Flex align='center'>
                            <Text size='h2'>VS</Text>
                        </Flex>
                        <Flex align='center' gap='lg'>
                            <Avatar
                                fallback={secondSelectedResult?.driver.name ?? '-'}
                                imgSrc={secondSelectedResult?.driver.avatar}
                                size='extraLarge'
                            />
                            <Column gap='md'>
                                <Text color='violet' size='h2'>
                                    {secondSelectedResult?.best_lap_time ?? '-'}
                                </Text>
                                <Text size='h3' weight='400'>
                                    {secondSelectedResult?.driver.name ?? '-'}
                                </Text>
                            </Column>
                        </Flex>
                    </CompareBox>
                    {!isDesktopWidth && (
                        <Flex direction='column' gap='md'>
                            <Text size='md' weight='600'>
                                Best laps
                            </Text>
                            <ResultsWrapper>
                                {eventLeaderboardResults?.map((result) => (
                                    <EventBestLapResultRow
                                        key={result.position}
                                        highlighted={result.position === selectedLapPositions[1]}
                                        isClickable
                                        onClick={() => handleSelectResult(result.position)}
                                        row={result}
                                        selected={result.position === selectedLapPositions[0]}
                                        visible
                                    />
                                ))}
                            </ResultsWrapper>
                        </Flex>
                    )}
                </Column>
            </MainColumnContent>
            <MainColumnAside>
                <UserPanel />
                <Flex direction='column' gap='md'>
                    <Text size='md' weight='600'>
                        Best laps
                    </Text>
                    <ResultsWrapper>
                        {eventLeaderboardResults?.map((result) => (
                            <EventBestLapResultRow
                                key={result.position}
                                highlighted={result.position === selectedLapPositions[1]}
                                isClickable
                                onClick={() => handleSelectResult(result.position)}
                                row={result}
                                selected={result.position === selectedLapPositions[0]}
                                visible
                            />
                        ))}
                    </ResultsWrapper>
                </Flex>
            </MainColumnAside>
        </>
    );
};

export default EventBestLapResults;
