import { styled } from 'Theme/stitches.config';

export const MessageWrapper = styled('div', {
    borderRadius: '$4',
    padding: '$16',
    display: 'flex',
    alignItems: 'flex-start',
    gap: '$10',

    variants: {
        variant: {
            error: {
                backgroundColor: '$backgroundError',
            },

            info: {
                backgroundColor: '$infoBlue',
            },
        },
        fullWidth: {
            true: {
                width: '100%',
            },
        },
    },
});
