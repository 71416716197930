import { styled } from '@stitches/react';

export const DimmerOverlay = styled('div', {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'rgba(193, 193, 193, 0.85)',
    display: 'grid',
    placeItems: 'center',
    zIndex: 10,
});
