import { StateCreator } from 'zustand';

import { StoreType } from './store';

type ConfirmationModalContext = 'leaveEvent' | 'deleteVehicle' | 'default';

interface ConfirmationModalData {
    isOpen: boolean;
    context: ConfirmationModalContext;
    eventId?: string;
    eventName?: string;
    vehicleName?: string;
    vehicleId?: string;
}

export type ModalSlice = {
    isTracksFilterModalOpen: boolean;
    setIsTracksFilterModalOpen: (value: boolean) => void;

    isDeleteTrackModalOpen: boolean;
    setIsDeleteTrackModalOpen: (value: boolean) => void;

    isDeleteEventModalOpen: boolean;
    setIsDeleteEventModalOpen: (value: boolean) => void;

    isManageVehicleOptionsModalOpen: boolean;
    setIsManageVehicleOptionsModalOpen: (value: boolean) => void;

    isLogOutModalOpen: boolean;
    setIsLogOutModalOpen: (value: boolean) => void;

    isReportModalOpen: boolean;
    setIsReportModalOpen: (value: boolean) => void;

    isReportConfirmationModalOpen: boolean;
    setIsReportConfirmationModalOpen: (value: boolean) => void;

    isDeleteAccountModalOpen: boolean;
    setIsDeleteAccountModalOpen: (value: boolean) => void;

    isEventsFilterModalOpen: boolean;
    setIsEventsFilterModalOpen: (value: boolean) => void;

    isAnalyzerLayoutViewModalOpen: boolean;
    setIsAnalyzerLayoutViewModalOpen: (value: boolean) => void;

    isAnalyzerChannelsModalOpen: boolean;
    setIsAnalyzerChannelsModalOpen: (value: boolean) => void;

    isDriversAndCoachesModalOpen: boolean;
    setIsDriversAndCoachesModalOpen: (value: boolean) => void;

    isPersonalInformationModalOpen: boolean;
    setIsPersonalInformationModalOpen: (value: boolean) => void;

    isProfessionalInformationModalOpen: boolean;
    setIsProfessionalInformationModalOpen: (value: boolean) => void;

    isInfoAfterSignUpModalOpen: boolean;
    setIsInfoAfterSignUpModalOpen: (value: boolean) => void;

    isConfirmationModalOpen: boolean;
    confirmationModalContext: ConfirmationModalContext;
    eventId?: string;
    evenName?: string;
    vehicleId?: string;
    vehicleName?: string;
    setIsConfirmationModalOpen: (data: ConfirmationModalData) => void;
};

export const modalsSlice: StateCreator<
    StoreType,
    [['zustand/immer', never], ['zustand/devtools', never]],
    [],
    ModalSlice
> = (set) => ({
    isTracksFilterModalOpen: false,
    setIsTracksFilterModalOpen: (value) =>
        set(
            (state) => {
                state.modals.isTracksFilterModalOpen = value;
            },
            false,
            'setIsTracksFilterModalOpen'
        ),

    isDeleteTrackModalOpen: false,
    setIsDeleteTrackModalOpen: (value) =>
        set(
            (state) => {
                state.modals.isDeleteTrackModalOpen = value;
            },
            false,
            'setIsDeleteTrackModalOpen'
        ),
    isDeleteEventModalOpen: false,
    setIsDeleteEventModalOpen: (value) =>
        set(
            (state) => {
                state.modals.isDeleteEventModalOpen = value;
            },
            false,
            'setIsDeleteEventModalOpen'
        ),

    isManageVehicleOptionsModalOpen: false,
    setIsManageVehicleOptionsModalOpen: (value) =>
        set(
            (state) => {
                state.modals.isManageVehicleOptionsModalOpen = value;
            },
            false,
            'setIsManageVehicleOptionsModalOpen'
        ),

    isLogOutModalOpen: false,
    setIsLogOutModalOpen: (value) =>
        set(
            (state) => {
                state.modals.isLogOutModalOpen = value;
            },
            false,
            'setIsLogOutModalOpen'
        ),

    isReportModalOpen: false,
    setIsReportModalOpen: (value) =>
        set(
            (state) => {
                state.modals.isReportModalOpen = value;
            },
            false,
            'setIsReportModalOpen'
        ),

    isReportConfirmationModalOpen: false,
    setIsReportConfirmationModalOpen: (value) =>
        set(
            (state) => {
                state.modals.isReportConfirmationModalOpen = value;
            },
            false,
            'setIsReportConfirmationModalOpen'
        ),

    isDeleteAccountModalOpen: false,
    setIsDeleteAccountModalOpen: (value) =>
        set(
            (state) => {
                state.modals.isDeleteAccountModalOpen = value;
            },
            false,
            'setIsDeleteAccountModalOpen'
        ),

    isEventsFilterModalOpen: false,
    setIsEventsFilterModalOpen: (value) =>
        set(
            (state) => {
                state.modals.isEventsFilterModalOpen = value;
            },
            false,
            'setIsEventsFilterModalOpen'
        ),

    isAnalyzerLayoutViewModalOpen: false,
    setIsAnalyzerLayoutViewModalOpen: (value) =>
        set(
            (state) => {
                state.modals.isAnalyzerLayoutViewModalOpen = value;
            },
            false,
            'setIsAnalyzerLayoutViewModalOpen'
        ),

    isAnalyzerChannelsModalOpen: false,
    setIsAnalyzerChannelsModalOpen: (value) =>
        set(
            (state) => {
                state.modals.isAnalyzerChannelsModalOpen = value;
            },
            false,
            'setIsAnalyzerChannelsModalOpen'
        ),

    isDriversAndCoachesModalOpen: false,
    setIsDriversAndCoachesModalOpen: (value) =>
        set(
            (state) => {
                state.modals.isDriversAndCoachesModalOpen = value;
            },
            false,
            'setIsDriversAndCoachesModalOpen'
        ),

    isPersonalInformationModalOpen: false,
    setIsPersonalInformationModalOpen: (value) =>
        set(
            (state) => {
                state.modals.isPersonalInformationModalOpen = value;
            },
            false,
            'setIsPersonalInformationModalOpen'
        ),

    isProfessionalInformationModalOpen: false,
    setIsProfessionalInformationModalOpen: (value) =>
        set(
            (state) => {
                state.modals.isProfessionalInformationModalOpen = value;
            },
            false,
            'setIsProfessionalInformationModalOpen'
        ),

    isInfoAfterSignUpModalOpen: true,
    setIsInfoAfterSignUpModalOpen: (value) =>
        set(
            (state) => {
                state.modals.isInfoAfterSignUpModalOpen = value;
            },
            false,
            'setIsInfoAfterSignUpModalOpen'
        ),

    isConfirmationModalOpen: false,
    confirmationModalContext: 'default',
    setIsConfirmationModalOpen: ({ isOpen, context, eventId, eventName, vehicleName, vehicleId }) =>
        set(
            (state) => {
                state.modals.confirmationModalContext = context;
                state.modals.isConfirmationModalOpen = isOpen;
                state.modals.eventId = eventId;
                state.modals.evenName = eventName;
                state.modals.vehicleId = vehicleId;
                state.modals.vehicleName = vehicleName;
            },
            false,
            'setIsConfirmationModalOpen'
        ),
});
