import { enableMapSet } from 'immer';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { AnalyzerSlice, analyzerSlice } from './analyzerSlice/analyzerSlice';
import { AuthSlice, authSlice } from './authSlice';
import { EventSlice, eventSlice } from './eventSlice';
import { generalSlice, GeneralSlice } from './generalSlice';
import { modalsSlice, ModalSlice } from './modalsSlice';
import { TrackSlice, trackSlice } from './trackSlice';
import { VehicleSlice, vehicleSlice } from './vehicleSlice';

export type StoreType = {
    auth: AuthSlice;
    modals: ModalSlice;
    general: GeneralSlice;
    track: TrackSlice;
    event: EventSlice;
    analyzer: AnalyzerSlice;
    vehicle: VehicleSlice;
};

enableMapSet();
// This func is for merging current state and persistet state because persisted state do not make copy of functions, copy only values
const deepMerge = (currentState: StoreType, persistedState: unknown) => {
    const persistedAuth = persistedState as StoreType;

    const token = persistedAuth.auth.token;

    return {
        ...currentState,
        auth: { ...currentState.auth, token: token },
    };
};

const useStore = create<StoreType>()(
    immer(
        devtools(
            persist(
                (...a) => ({
                    auth: authSlice(...a),
                    modals: modalsSlice(...a),
                    general: generalSlice(...a),
                    track: trackSlice(...a),
                    event: eventSlice(...a),
                    analyzer: analyzerSlice(...a),
                    vehicle: vehicleSlice(...a),
                }),
                {
                    name: 'token-store',

                    //define what element will be store in browser storage
                    partialize: (state) => ({
                        auth: {
                            ...state.auth,
                        },
                    }),
                    merge: (persistedState, currentState) =>
                        deepMerge(currentState, persistedState),
                }
            )
        )
    )
);

export default useStore;
