// HOME
export const WALL_KEY = 'wall';

// AUTH
export const ACTIVATE_ACCOUNT_KEY = 'activateAccount';

// EXPLORE
export const COUNTRIES_LIST_KEY = 'countriesList';
export const TRACKS_LIST_KEY = 'tracksList';
export const TRACK_VISITED_LIST_KEY = 'tracksVisitedList';
export const TRACK_CREATED_LIST_KEY = 'tracksCreatedList';

export const TRACK_DETAILS_KEY = 'trackDetails';

export const TRACK_LAYOUT_DETAILS_KEY = 'trackLayoutDetails';
export const TRACK_LAYOUT_RESULTS_KEY = 'trackLayoutResults';
export const TRACK_LAYOUT_METADATA_KEY = 'trackLayoutMetadata';
export const DRIVERS_LIST_KEY = 'drivers';
export const EVENTS_LIST_KEY = 'eventsList';
export const EVENT_DETAILS_KEY = 'eventsDetails';
export const EVENT_DETAILS_BY_ID_KEY = 'eventsDetailsById';
export const EVENT_LEADERBOARD_KEY = 'eventsLeaderboard';

export const VEHICLE_DETAILS_KEY = 'vehicleDetails';
export const VEHICLE_TECHNICAL_OPTIONS_KEY = 'vehicleTechnicalOptions';

// ACTIVITY
export const USER_PROFILE_KEY = 'userProfile';
export const USER_SAVED_TRACKS_KEY = 'userSavedTracks';
export const USER_ACTIVITY_KEY = 'userActivity';

// USER
export const USER_SESSIONS_KEY = 'userSessionsList';
export const USER_VEHICLES_KEY = 'userVehiclesList';
export const USER_EVENTS_CREATED_KEY = 'userEventsCreatedList';
export const USER_EVENTS_JOINED_KEY = 'userEventsJoinedList';
export const SELECTED_USER_DETAILS_KEY = 'selectedUserDetails';

//SESSION
export const SESSION_LAPS_KEY = 'sessionLapSummary';
export const SESSION_DETAILS = 'sessionDetails';

export const SESSION_DEBUG = 'sessionDebug';

export const SESSION_LAP_POSITIONS_KEY = 'sessionLapPositions';

export const SESSION_TIME_POSITIONS_KEY = 'sessionTimePositions';

//NOTIFICATIONS
export const NOTIFICATIONS_KEY = 'notifications';

/////////////////////////////
// **** MUTATION KEYS **** //
/////////////////////////////

export const USER_LIKE_POST_MUTATION_KEY = 'userLikePost';
export const USER_DISLIKE_POST_MUTATION_KEY = 'userDislikePost';
export const USER_JOIN_EVENT_MUTATION_KEY = 'userJoinEvent';
export const UPLOAD_IMAGE_MUTATION_KEY = 'uploadImage';
export const DELETE_IMAGE_MUTATION_KEY = 'deleteImage';
