import { styled } from 'Theme/stitches.config';

export const MapWrapper = styled('div', {
    height: '644px',
    border: '1px solid $mainWhite',
    borderRadius: '$4',
    overflow: 'hidden',
});

export const CompareBox = styled('div', {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    borderRadius: '$4',
    background: '$mainWhite',
    pad: '$20',
    justifyContent: 'center',
    gap: '$24',
});

export const ResultsWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '$8',
    height: 'calc(100vh - 128px)',
    overflowY: 'auto',
    pb: '$24',
});
