import {
    COUNTRIES_LIST_KEY,
    DRIVERS_LIST_KEY,
    EVENTS_LIST_KEY,
    EVENT_DETAILS_KEY,
    TRACKS_LIST_KEY,
    SESSION_LAPS_KEY,
    TRACK_DETAILS_KEY,
    TRACK_LAYOUT_DETAILS_KEY,
    TRACK_LAYOUT_RESULTS_KEY,
    TRACK_LAYOUT_METADATA_KEY,
    TRACK_VISITED_LIST_KEY,
    TRACK_CREATED_LIST_KEY,
    EVENT_DETAILS_BY_ID_KEY,
} from './queryKeys';
import { ApiResponse, DataWithPaginator, Paginator } from './response';
import { TrackDTO } from './track';
import { AccountType } from 'Consts/account';
import { httpClient } from 'Infrastructure/http/client';

// TRACKS
export interface Country {
    code: string;
    name: string;
}

const getCountries = async () => {
    const response = await httpClient.get(`/tracks/countries`);
    return response.data;
};

export const getCountriesQuery = () => ({
    queryKey: [COUNTRIES_LIST_KEY],
    queryFn: async () => getCountries(),
});

export const getCountriesByQueryQuery = (queryString: string) => ({
    queryKey: [COUNTRIES_LIST_KEY, queryString],
    queryFn: async (): Promise<Country[]> => {
        const response = await httpClient.get(`/tracks/countries?name=${queryString}`);
        return response.data;
    },
});

export interface GetTracksArgs {
    page?: string;
    name?: string;
    country?: string;
    track?: string;
    sort?: string;
}

export const getTracksList = async (args: GetTracksArgs) => {
    const params = new URLSearchParams({ ...args });
    const response = await httpClient.get(`/tracks?${params.toString()}`);
    return response.data;
};

export const getTracksListQuery = (args: GetTracksArgs) => ({
    queryKey: [TRACKS_LIST_KEY, ...Object.values(args)],
    queryFn: async ({ pageParam = 1 }): Promise<TrackDTO> =>
        getTracksList({ ...args, page: pageParam.toString() }),
});

export const saveTrack = async (trackId: string) => {
    const response = await httpClient.post(`/tracks/${trackId}/save`);
    return response.data;
};

export const saveTrackMutation = () => ({
    mutationFn: async (trackId: string) => saveTrack(trackId),
});

export interface GetTrackDetailsSlugArgs {
    slug: string;
}

export interface TrackDetailsResponse {
    id: number;
    name: string;
    slug: string;
    address: Address;
    location: Location;
    layouts: Layout[];
    challenges: string[];
    description: string;
    contact: Contact;
    social_media: SocialMedia;
    user_saved: boolean;
    media: Media[];
    is_creator: boolean;
}

export interface Address {
    country: Country;
    state?: string;
    city?: string;
    zip_code?: string;
    street?: string;
}

export interface Location {
    latitude: number;
    longitude: number;
}

export interface Layout {
    id: number;
    name: string;
    slug: string;
    length: number;
}

export interface Contact {
    website: string;
    phone: string;
    email: string;
}

export interface SocialMedia {
    facebook: string;
    youtube: string;
    instagram: string;
    twitter: string;
}

export const getTrackDetailsQuery = (initialState: GetTrackDetailsSlugArgs) => ({
    queryKey: [TRACK_DETAILS_KEY, ...Object.values(initialState)],
    queryFn: async () => getTrackDetails(initialState),
});

export const getTrackDetails = async (args: GetTrackDetailsSlugArgs) => {
    const response = await httpClient.get(`/tracks/${args.slug}`);
    return response.data;
};

export const deleteTrack = async (trackId?: number) => {
    const response = await httpClient.delete(`/tracks/${trackId}`);
    return response.data;
};

export const deleteTrackMutation = () => ({
    mutationFn: async (trackId?: number) => deleteTrack(trackId),
});

export const getVisitedTrackList = async ({ name, page }: Omit<GetTracksArgs, 'country'>) => {
    const response = await httpClient.get(`/tracks/user/${name}/visited?page=${page}`);
    return response.data;
};

export const getVisitedTrackListQuery = (driverSlug: string) => ({
    queryKey: [TRACK_VISITED_LIST_KEY, driverSlug],
    queryFn: async ({ pageParam = 1 }) =>
        getVisitedTrackList({ name: driverSlug, page: pageParam.toString() }),
});

export const getCreatedTrackList = async ({ name, page }: Omit<GetTracksArgs, 'country'>) => {
    const response = await httpClient.get(`/tracks/user/${name}/created?page=${page}`);
    return response.data;
};

export const getCreatedTrackListQuery = (driverSlug: string) => ({
    queryKey: [TRACK_CREATED_LIST_KEY, driverSlug],
    queryFn: async ({ pageParam = 1 }) =>
        getCreatedTrackList({ name: driverSlug, page: pageParam.toString() }),
});

//Track layout details
export interface GetTrackLayoutDetailsSlugArgs {
    layoutSlug: string;
}

export interface TrackLayoutDetailsResponse {
    id: number;
    name: string;
    slug: string;
    length: number;
    track: TrackLayoutDetailsTrack;
}

export interface TrackLayoutDetailsTrack {
    id: number;
    name: string;
    slug: string;
    creator_id: string;
}

export const getTrackLayoutDetailsQuery = (initialState: GetTrackLayoutDetailsSlugArgs) => ({
    queryKey: [TRACK_LAYOUT_DETAILS_KEY, ...Object.values(initialState)],
    queryFn: async () => getTrackLayoutDetails(initialState),
});

export const getTrackLayoutDetails = async (args: GetTrackLayoutDetailsSlugArgs) => {
    const response = await httpClient.get(`/tracks/layout/slug/${args.layoutSlug}`);
    return response.data;
};

//Track layout results
export interface GetTrackLayoutLeaderboardArgs {
    layoutId: number;
}

export interface TrackLayoutLeaderboardResponse {
    data: LeaderboardResult[];
    paginator: Paginator;
    user_position: number;
}

export interface LeaderboardResult {
    position: number;
    session_id: string;
    vehicle_id: string;
    best_lap_time: number;
    vehicle_name: string;
    vehicle_type: string;
    driver: LeaderboardResultDriver;
    updated: string;
}

export interface LeaderboardResultDriver {
    id: string;
    name: string;
}

export const getTrackLayoutLeaderboardQuery = (initialState: GetTrackLayoutLeaderboardArgs) => ({
    queryKey: [TRACK_LAYOUT_RESULTS_KEY, ...Object.values(initialState)],
    queryFn: async () => getTrackLayoutLeaderboard(initialState),
});

export const getTrackLayoutLeaderboard = async (args: GetTrackLayoutLeaderboardArgs) => {
    const response = await httpClient.get(`/tracks/layout/${args.layoutId}/leaderboard`);
    return response.data;
};

export interface GetTrackLayoutMetadataArgs {
    layoutId: number;
}

export interface TrackLayoutMetadataResponse {
    line_point_1: Location;
    line_point_2: Location;
}

export const getTrackLayoutMetadataQuery = (initialState: GetTrackLayoutMetadataArgs) => ({
    queryKey: [TRACK_LAYOUT_METADATA_KEY, ...Object.values(initialState)],
    queryFn: async () => getTrackLayoutMetadata(initialState),
});

export const getTrackLayoutMetadata = async (args: GetTrackLayoutMetadataArgs) => {
    const response = await httpClient.get(`/tracks/metadata/${args.layoutId}`);
    return response.data;
};

// DRIVERS AND COACHES

export interface Driver {
    id: string;
    avatar: string;
    first_name: string;
    username: string;
    last_name: string;
    nationality: string;
    account_type: AccountType;
    created: string;
    stats: {
        sessions_count: number;
        total_distance: number;
        visited_tracks: number;
    };
}
export type DriverDTO = DataWithPaginator<Driver[]>;

export interface GetDriversArgs {
    page: string;
    name?: string;
    country?: string;
    account_type?: string;
}

export const getDrivers = async (args: GetDriversArgs) => {
    const params = new URLSearchParams({ ...args });
    const response = await httpClient.get(`/drivers?${params.toString()}`);
    return response.data;
};

export const getDriversQuery = (initialState: GetDriversArgs) => ({
    queryKey: [DRIVERS_LIST_KEY, ...Object.values(initialState)],
    queryFn: async ({ pageParam = 1 }): Promise<DriverDTO> =>
        getDrivers({ ...initialState, page: pageParam.toString() }),
});

// EVENTS
export interface Media {
    id: string;
    thumbs: {
        '400_300': string;
        '800_600': string;
        '1200_800': string;
    };
}

export interface EventData {
    id: string;
    ends: string;
    name: string;
    slug: string;
    starts: string;
    upcoming: boolean;
    creator: string;
    user_joined: boolean;
    media: Media[];
    address: {
        city: string;
        state: string;
        street: string;
        zip_code: string;
        country: {
            name: string;
            code: string;
        };
    };
    track_id?: number;
}
export interface EventDTO {
    data: EventData[];
    paginator: {
        page: number;
        pages_count: number;
        rows_per_page: number;
    };
}

export interface GetEventsArgs {
    page: string;
    name?: string;
    country?: string;
    start_date?: string;
    sort?: string;
}

export const getEvents = async (args: GetEventsArgs) => {
    const params = new URLSearchParams({ ...args });
    const response = await httpClient.get(`/events?${params.toString()}`);
    return response.data;
};

export const getEventsQuery = (initialState: GetEventsArgs) => ({
    queryKey: [EVENTS_LIST_KEY, ...Object.values(initialState)],
    queryFn: async ({ pageParam = 1 }): Promise<EventDTO> =>
        getEvents({ ...initialState, page: pageParam.toString() }),
});

export interface EventDetailsData {
    id: string;
    name: string;
    slug: string;
    starts: string;
    ends: string;
    address: {
        country: { name: string; code: string };
        state: string;
        street: string;
        zip_code: string;
        city: string;
    };
    upcoming: boolean;
    media: Media[];
    description: string;
    surface: string;
    user_joined: boolean;
    creator: string;
    track_id?: number;
    participants: JoinedUsersData[];
    track?: TrackDetailsResponse;
}

export const getEventDetails = async (eventSlug: string) => {
    const response = await httpClient.get(`/events/${eventSlug}`);
    return response.data;
};

export const getEventDetailsQuery = (slug: string) => ({
    queryKey: [EVENT_DETAILS_KEY, slug],
    queryFn: async () => getEventDetails(slug),
});

export const getEventDetailsById = async (eventId: string) => {
    const response = await httpClient.get(`/events/id/${eventId}`);
    return response.data;
};

export const getEventDetailsByIdQuery = (eventId: string) => ({
    queryKey: [EVENT_DETAILS_BY_ID_KEY, eventId],
    queryFn: async () => getEventDetailsById(eventId),
});

export interface AddEventArgs {
    name: string;
    description: string;
    starts: string | Date;
    ends: string | Date;
    surface: string;
    address: {
        country: {
            code: string;
        };
        state: string;
        street: string;
        zip_code: string;
        city: string;
    };
    media_id?: string[];
}

export const addEventDetails = async (args: AddEventArgs) => {
    const response = await httpClient.post(`/events`, { ...args });
    return response.data;
};

export const addEventMutation = () => ({
    mutationFn: async (args: AddEventArgs) => addEventDetails(args),
});

export const deleteEvent = async (eventId: string) => {
    const response = await httpClient.delete(`/events/${eventId}`);
    return response.data;
};

export const deleteEventMutation = () => ({
    mutationFn: async (eventId: string) => deleteEvent(eventId),
});

export interface EditEventArgs {
    eventId: string;
    args: AddEventArgs;
}

export const editEvent = async (args: EditEventArgs) => {
    const response = await httpClient.post(`/events/${args.eventId}`, args.args);
    return response.data;
};

export const editEventMutation = () => ({
    mutationFn: async (args: EditEventArgs) => editEvent(args),
});

export interface JoinedUsersData {
    id: string;
    username: string;
    name: string;
    avatar: string;
    account_type: string;
}

export interface SessionLapsSummaryRequest {
    sessionId: string;
}

export interface SessionLapsSummary {
    lap_number: number;
    lap_time: number;
    fully_driven: boolean;
    distance: number;
    start: number;
    end: number;
    stats?: Record<string, number>;
    best_lap_delta?: number;
}

export const getSessionLaps = async (args: SessionLapsSummaryRequest) => {
    const response = await httpClient.get(`/analyze/session/${args.sessionId}/laps`);
    return response.data;
};

export const getSessionLapsQuery = (args: SessionLapsSummaryRequest, enabled: boolean) => ({
    queryKey: [SESSION_LAPS_KEY, ...Object.values(args)],
    queryFn: async (): Promise<ApiResponse<SessionLapsSummary[]>> => getSessionLaps(args),
    enabled: enabled,
});
