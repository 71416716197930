import { APIProvider } from '@vis.gl/react-google-maps';
import { FC, ReactNode } from 'react';

interface MapWrapperProps {
    children: ReactNode; // This is the type for nested components
}
const MapWrapper: FC<MapWrapperProps> = ({ children }) => {
    return <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY}>{children}</APIProvider>;
};

export default MapWrapper;
