import { PathLayer } from '@deck.gl/layers';
import { ScenegraphLayer } from '@deck.gl/mesh-layers';
import { TextLayer } from 'deck.gl';

import { Frame, LapInfo, Tick } from 'Organisms/MapLapOverlay/types';

import { TrackLayoutMetadataResponse } from 'Services/exploreQueries';

const MODEL_URL =
    'https://raw.githubusercontent.com/visgl/deck.gl-data/master/examples/google-3d/truck.gltf'; // eslint-disable-line

export const getTurnAngle = (startHeading: number, endHeading: number): number => {
    let turnAngle = endHeading - startHeading;
    if (turnAngle < -180) turnAngle += 360;
    if (turnAngle > 180) turnAngle -= 360;
    return turnAngle;
};

export const getFrame = (ticks: [Tick], timestamp: number, vehicleNumber: number): Frame => {
    const i = ticks.findIndex((s) => s.timestamp >= timestamp);
    if (i === -1) {
        throw new Error('No frame found');
    }

    const startState = ticks[i - 1];
    const endState = ticks[i];
    const r = (timestamp - startState.timestamp) / (endState.timestamp - startState.timestamp);

    return {
        vehicleNumber: vehicleNumber,
        point: [
            startState.lon * (1 - r) + endState.lon * r,
            startState.lat * (1 - r) + endState.lat * r,
        ],
        heading: startState.heading + getTurnAngle(startState.heading, endState.heading) * r,
        speed: (startState.speed * (1 - r) + endState.speed * r) * 3.6,
    };
};

export const lapInfo = (data: [Tick]): LapInfo => {
    let minSpeed: number = Number.MAX_SAFE_INTEGER;
    let maxSpeed: number = -1;
    data.forEach((tick) => {
        if (minSpeed > tick.speed) {
            minSpeed = tick.speed;
        }
        if (maxSpeed < tick.speed) {
            maxSpeed = tick.speed;
        }
    });

    return {
        startTime: data[0].timestamp,
        endTime: data[data.length - 1].timestamp,
        totalTime: data[data.length - 1].timestamp - data[0].timestamp,
        minSpeed,
        maxSpeed,
    };
};
export const tackStartFinishPath = (data: TrackLayoutMetadataResponse): PathLayer => {
    // Obiekt danych
    const pathData = [
        {
            path: [[data.line_point_1.longitude, data.line_point_1.latitude], [data.line_point_2.longitude, data.line_point_2.latitude]],
            name: 'Example Path'
        }
    ];


    return new PathLayer({
        id: 'start-finish-path-layer',
        data: pathData,
        getPath: d => d.path,
        getColor: [46, 97, 213, 255],
        getWidth: 1,
        opacity: 0.5,
        pickable: false
    });

}
export const layerPath = (data: [[Tick]], mainLapInfo: LapInfo): PathLayer => {
    return new PathLayer({
        id: 'trip-lines',
        data: data,
        getPath: (d) => {
            return d.map((tick: Tick) => {
                return [tick.lon, tick.lat];
            });
        },
        getColor: (pointsData) => {
            if (!pointsData || pointsData.length === 0) {
                return [0, 0, 0]; // Default color if no data
            }

            // Example: converting speed to a color (you might want to adjust this logic)
            return pointsData.map((point: Tick) => {
                const normalizedSpeed =
                    (point.speed - mainLapInfo.minSpeed) /
                    (mainLapInfo.maxSpeed - mainLapInfo.minSpeed);
                const intensity = Math.min(Math.max(normalizedSpeed, 0), 1);
                return [255 * (1 - intensity), 255 * intensity, 0, 200]; // Color from red to green based on normalized speed
            });
        },
        opacity: 0.5,
        getWidth: 2, // Set a fixed line width
        lineWidthUnits: 'meters', // Use meters for line width
        jointRounded: true, // Make the line joints round
        capRounded: true, // Make the line caps round
    });
};

export const scenegraphLayer = (frames: Frame[]): ScenegraphLayer => {
    return new ScenegraphLayer({
        id: 'truck',
        data: frames,
        scenegraph: MODEL_URL,
        sizeScale: 1.5,
        getPosition: (d: { point: [number, number, number] }) => d.point,
        getTranslation: [0, 0, 1],
        getOrientation: (d: { heading: number }) => [0, 180- d.heading, 90],
        _lighting: 'pbr',
        getColor: (d: Frame) => {
            if (d.vehicleNumber === 0) {
                return [146, 97, 212, 255];
            }
            return [46, 97, 212, 255];
        },
    });
};

export const textLayer = (frames: Frame[]): TextLayer => {
    return new TextLayer({
        id: 'text-layer',
        data: frames,
        getPosition: (d) => d.point,
        getText: (d) => `${d.speed.toFixed(0)} km/h`,
        getSize: 14,
        sizeScale: 1,
        getColor: (d) => {
            if (d.vehicleNumber === 0) {
                return [146, 97, 212, 250];
            }
            return [46, 97, 212, 250];
        },
        billboard: true,
        background: true,
        getPolygonOffset: ({ layerIndex }) => [0, -layerIndex],
        getBackgroundColor: [229, 229, 229, 180],
        getBorderColor: [255, 255, 255, 0],
        getPixelOffset: (d) => {
            if (d.vehicleNumber === 0) {
                return [45, 45];
            }

            return [-45, 45];
        },
    });
};
