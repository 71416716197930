import { GoogleMapsOverlay as DeckOverlay } from '@deck.gl/google-maps';
import { PathLayer } from '@deck.gl/layers';
import { useQuery } from '@tanstack/react-query';
import { useMap } from '@vis.gl/react-google-maps';
import { PropsWithChildren, useEffect, useRef } from 'react';

import { MapOverlayWrapper, Overlay } from './styles';
import { Text } from 'Atoms/Text';
import {
    getFrame,
    lapInfo,
    layerPath,
    scenegraphLayer, tackStartFinishPath,
    textLayer,
} from 'Organisms/MapLapOverlay/animationUtils';
import { useMapLapOverlayContext } from 'Organisms/MapLapOverlay/context';
import PlayButton from 'Organisms/MapLapOverlay/playButton';
import { LapInfo, Tick } from 'Organisms/MapLapOverlay/types';

import { TrackLayoutMetadataResponse } from 'Services/exploreQueries';
import { getSessionLapPositionsQuery } from 'Services/sessionsQueries';

interface Lap {
    sessionId: string;
    lapNumber: number;
}

export type MapLaps = Lap[];

// Extend PropsWithChildren
type MapOverlayProps = PropsWithChildren<{
    laps: MapLaps;
    startFinishLine?: TrackLayoutMetadataResponse;
}>;

const MapOverlay = ({ children, laps, startFinishLine }: MapOverlayProps) => {
    const defaultElapseTime = 0.001;
    const map = useMap();

    const { isPlaying, setIsPlaying } = useMapLapOverlayContext();

    const overlay = useRef<DeckOverlay>();
    const requestRef = useRef<number>(); //animation frame
    const elapsedTimeRef = useRef<number>(defaultElapseTime); //elapsed time of animation
    const lastTimeRef = useRef<number>();
    const mainLapInfo = useRef<LapInfo>();
    const pathLayer = useRef<PathLayer>(); //layer for the path used in the animation
    const startFinishLayer = useRef<PathLayer>(); //layer for the start finish line

    const { data } = useQuery<[[Tick]]>({
        ...getSessionLapPositionsQuery(laps),
        enabled: laps.length > 0,
    });

    useEffect(() => {
        if (startFinishLine) {
            startFinishLayer.current = tackStartFinishPath(startFinishLine);
        }
    }, [startFinishLine]);

    useEffect(() => {
        if (!map) {
            return;
        }
        if (!overlay.current) {
            overlay.current = new DeckOverlay({});
            overlay.current.setMap(map);
        }

        const layers = [];
        if (startFinishLayer.current) {
            layers.push(startFinishLayer.current);
        }


        if (data) {
            if(!mainLapInfo.current) {
                mainLapInfo.current = lapInfo(data[0]);
            }
            if(!pathLayer.current) {
                pathLayer.current = layerPath(data, mainLapInfo.current);
            }


            const frames = data.map((ticks, i) => {
                const currentLapInfo = lapInfo(ticks);
                return getFrame(ticks, currentLapInfo.startTime + 0.01, i);
            });

            layers.push(pathLayer.current, scenegraphLayer(frames), textLayer(frames));
            overlay.current?.setProps({
                layers: layers,
            });

            const initFrame = frames[0];
            map.moveCamera({
                center: { lat: initFrame.point[1], lng: initFrame.point[0] },
                heading: 360 - initFrame.heading,
                tilt: 45.5,
                zoom: 19,
            });
        }

    }, [data, laps, map, startFinishLine]);

    const setOverlay = () => {
        if (!data || !map) {
            return;
        }
        try {
            const frames = data.map((ticks, i) => {
                const currentLapInfo = lapInfo(ticks);
                return getFrame(ticks, currentLapInfo.startTime + elapsedTimeRef.current, i);
            });

            map.moveCamera({
                center: { lat: frames[0].point[1], lng: frames[0].point[0] },
                heading: 360 - frames[0].heading,
            });

            //@todo - work on performance of deck update layers, see:
            // https://deck.gl/docs/developer-guide/performance now we replace all layers
            const layers = [pathLayer.current, scenegraphLayer(frames), textLayer(frames)];
            if (startFinishLayer.current) {
                layers.push(startFinishLayer.current);
            }
            overlay.current?.setProps({ layers });
        } catch (e) {
            console.error('Error getting frames', e);
            resetAnimation();
            return;
        }
    };

    const stopAnimation = () => {
        if (requestRef.current) {
            cancelAnimationFrame(requestRef.current);
        }
        setIsPlaying(false);
    };

    const startAnimation = () => {
        if (!isPlaying) {
            lastTimeRef.current = undefined;
            requestRef.current = requestAnimationFrame(animate);
            setIsPlaying(true);
        }
    };

    const animate = (time: number) => {
        if (data === undefined) {
            return;
        }
        if (!lastTimeRef.current) {
            lastTimeRef.current = time;
        }

        elapsedTimeRef.current += time - lastTimeRef.current;
        lastTimeRef.current = time;

        setOverlay();

        if (elapsedTimeRef.current < mainLapInfo.current!.endTime) {
            requestRef.current = requestAnimationFrame(animate);
        } else {
            resetAnimation();
        }
    };

    const resetAnimation = () => {
        if (requestRef.current) {
            cancelAnimationFrame(requestRef.current);
        }
        setIsPlaying(false);
        elapsedTimeRef.current = defaultElapseTime; // Reset for next play
        setOverlay(); //reset overlay
    };

    return (
        <MapOverlayWrapper>
            {children}

            {data !== undefined && (
                <PlayButton
                    isPlaying={isPlaying}
                    startAnimation={startAnimation}
                    stopAnimation={stopAnimation}
                />
            )}

            {data === undefined && (
                <Overlay show={!isPlaying}>
                    <Text color="white" size="h2">
                        Loading data...
                    </Text>
                </Overlay>
            )}
        </MapOverlayWrapper>
    );
};

export default MapOverlay;
