import { CSS } from '@stitches/react';
import { FC } from 'react';

import { DotDivider } from './styles';
import Icon from '../Icon';

interface Props {
    size?: number;
    css?: CSS;
}

const BlackDotDivider: FC<Props> = ({ size = 6, css }) => (
    <DotDivider css={css}>
        <Icon name='dotDivider' size={size} />
    </DotDivider>
);

export default BlackDotDivider;
